.section--copy-grid {
	.grid {
		margin-top: -$reflex-grid-spacing;
		margin-bottom: -$reflex-grid-spacing;
	}

	.grid__item {
		img {
			display: block;
		}

		p {
			color: $text;
		}

		p:last-child {
			margin-bottom: 0;
		}

		.item__link {
			width: 1.5rem;
			height: 1rem;
			margin-top: 2rem;
			display: block;
		}
	}
}
