.choices {
	.choices__inner {
		@extend %form-control;
		background-color: transparent;
		padding-bottom: 1rem;

		.choices__list--single {
			padding: 0;
		}
	}

	.choices__list--dropdown {
		@extend %light-background;

		z-index: 11;
		border: 0.2rem solid $accent-secondary;
		border-top: none;
		border-radius: 0 0 0.6rem 0.6rem;
		margin-top: -0.2rem;

		.choices__item {
			@extend %p;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		.choices__item--selectable.is-highlighted {
			background-color: $outline-contrast;
		}
	}

	.choices__placeholder {
		opacity: 1;
	}

	&.is-focused,
	&.is-open {
		.choices__inner {
			border-color: $accent-secondary;
		}
	}

	&.is-open {
		.choices__inner {
			border-radius: 0.6rem 0.6rem 0 0;
		}
	}

	&.is-disabled {
		.choices__inner,
		.choices__input {
			background-color: #ffffff;
			opacity: 0.5;
		}
	}
}
