.section--calculator{

	.container{
		position: relative;
		padding-top: 5rem;
		padding-bottom: 5rem;
		margin-top: 4rem;
		margin-bottom: 4rem;

		> * {
			z-index: 2;
			position: relative;
		}

		&:before{
			content: '';
			height: 3.8rem;
			width: calc(100% + ((100vw - 100%) / 2) );
			background-image: url("../../../images/transitions/small--bottom-grey.svg");
			background-size: 100% 3.8rem;
			position: absolute;
			top: -3.8rem;
			right: 0;
			z-index: 1;
		}

		&:after{
			content: '';
			height: calc(100% + 3.8rem);
			width: calc(100% + ((100vw - 100%) / 2) );
			background-image: linear-gradient(#f6f6f6, #f6f6f6), url("../../../images/transitions/small--top-grey.svg");
			background-size: 100% calc(100% - 3.8rem), 100% 3.8rem;
			background-position: center top, 100% 100%;
			background-repeat: no-repeat;
			position: absolute;
			bottom: -3.8rem;
			right: 0;
			z-index: 1;
		}
	}

	.calculator-form{
		max-width: 63rem;
		z-index: 3;
		position: relative;

		.h2{
			margin-bottom: 2rem;
			display: block;
		}

		.choices{
			display: inline-block;
			vertical-align: middle;
			width: auto;
			text-align: left;
			text-align-last: left;
			min-width: 10rem;
			margin-left: 1rem;
			margin-right: 1rem;
			z-index: 99;

			.choices__inner{
				border: .2rem solid $accent-secondary;
				border-radius: .6rem;
				background-color: #f6f6f6;
				padding: 0 2rem;
				line-height: 0;
			}

			.choices__list--single{
				padding: 0;

				.choices__item{
					font-size: 3.6rem;
					line-height: 4.5rem;
				}
			}

			.choices__list--dropdown{
				border: .2rem solid $accent-secondary;
				border-radius: 0 0 .6rem .6rem;
				background-color: #f6f6f6;

				.choices__item{
					font-size: 3.6rem;
					padding: 0.4rem 2rem;
				}

				.choices__item--selectable{
					padding-right: 1rem;

					&:after{
						display: none;
					}
				}
			}


			&.is-open:not(.is-flipped){
				.choices__inner{
					border-radius: .6rem .6rem 0 0;
					border-bottom: .2rem solid transparent;
				}
			}

			&.is-open.is-flipped{
				.choices__inner{
					border-radius: 0 0 .6rem .6rem;
					border-top: .2rem solid transparent;
				}

				.choices__list--dropdown{
					border-radius: .6rem .6rem 0 0;
				}
			}
		}

		small {
			display: block;
			max-width: 36rem;
			margin-bottom: 3rem;
		}
	}

	.calculator-result{
		color: $accent-primary;
		height: 36rem;
		width: 36rem;
		position: absolute;
		right: -18rem;
		top: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		background-image: url("../../../images/quote-roundel.svg");
		background-size: 36rem;
		background-repeat: no-repeat;


		&__amount{
			@extend %heading-1;
			font-size: 7rem;
			font-weight: 700;
		}

		.h3{
			font-weight: normal;
		}
	}


	@media (max-width: 1600px) {
		.container {
			&:before,
			&:after {
				padding-right: 20rem;
				background-clip: content-box;
			}

			.calculator-result {
				right: 0;
			}
		}
	}

	@include respond-to(large) {
		.container {
			padding-top: 4rem;
			padding-bottom: 4rem;

			.calculator-result {
				height: 32rem;
				width: 32rem;
				background-size: 32rem;
				right: 2rem;
			}
		}
	}

	@include respond-to(medium) {
		.container {
			margin-right: auto;
			margin-bottom: 17rem;
			margin-top: 0;

			&:before,
			&:after {
				padding-right: 0;
			}

			.calculator-form {
				margin: 0 auto 4rem;
				text-align: center;
				text-align-last: center;

				small {
					margin: 0 auto 3rem;
				}
			}

			.calculator-result {
				right: 0;
				position: relative;
				margin-bottom: -22rem;
			}
		}
	}

	@include respond-to(xxsmall) {
		.container {
			margin-bottom: 16rem;

			.calculator-result {
				width: 26.5rem;
				height: 26.5rem;
				background-size: 26.5rem;
				margin-bottom: -18rem;

				p, span {
					margin-bottom: 0;
				}

				.h3 {
					font-size: 1.8rem;
				}
			}
		}

	}
}
