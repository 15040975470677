
%transition-none-top {
	&:before {
		content: none !important;
	}
}

%transition-none-bottom {
	&:after {
		content: none !important;
	}
}

%transition-small-top {
	@extend %transition-top;

	&:before {
		padding-bottom: 3.8rem;
		background-image: url(../../images/transitions/small--top.svg), linear-gradient(#ffffff, #ffffff);
		background-size: 100% 3.9rem, 100%;
	}

	@include respond-to(small){

		&:before {
			padding-bottom: 2rem;
			background-size: 76.7rem 2rem, 100%;
		}

	}
}

%transition-small-bottom {
	@extend %transition-bottom;

	&:after {
		padding-top: 3.8rem;
		background-image: url(../../images/transitions/small--bottom.svg), linear-gradient(#ffffff, #ffffff);
		background-size: 100% 3.9rem, 100%;
	}

	@include respond-to(small){

		&:after {
			padding-top: 2rem;
			background-size: 76.7rem 2.1rem, 100%;
		}
	}
}

%transition-tear-top {
	@extend %transition-top;
	padding-top: 13.8rem;

	&:before {
		padding-bottom: 13.8rem;
		background-image: url(../../images/transitions/curve--tear.svg), linear-gradient(#ffffff, #ffffff);
		background-repeat: no-repeat;
		background-size: 100% 13.9rem, 100%;
	}

	@include respond-to(small){
		padding-top: 6.2rem;

		&:before {
			padding-bottom: 6.2rem;
			background-size: 76.7rem 6.3rem, 100%;
		}
	}
}

%transition-curve-in-top {
	@extend %transition-top;
	padding-top: 13rem;

	&:before {
		padding-bottom: 13rem;
		background-image: url(../../images/transitions/curve--in-top.svg), linear-gradient(#ffffff, #ffffff);
		background-repeat: no-repeat;
		background-size: 100% 13.1rem, 100%;
	}

	@include respond-to(small){
		padding-top: 6.2rem;

		&:before {
			padding-bottom: 6.2rem;
			background-size: 76.7rem 6.3rem, 100%;
		}
	}
}

%transition-curve-in-bottom {
	@extend %transition-bottom;
	padding-bottom: 13rem;

	&:after {
		padding-bottom: 13rem;
		background-image: url(../../images/transitions/curve--in-bottom.svg), linear-gradient(#ffffff, #ffffff);
		background-repeat: no-repeat;
		background-size: 100% 13.1rem, 100%;
	}

	@include respond-to(small){
		padding-bottom: 6.2rem;

		&:after {
			padding-bottom: 6.2rem;
			background-size: 76.7rem 6.3rem, 100%;
		}
	}
}

%transition-curve-out-top {
	@extend %transition-top;
	padding-top: 15rem;

	&:before {
		padding-bottom: 15rem;
		background-image: url(../../images/transitions/curve--out-top.svg), linear-gradient(#ffffff, #ffffff);
		background-repeat: no-repeat;
		background-size: 100% 15.1rem, 100%;
	}

	@include respond-to(small){
		padding-top: 6.2rem;

		&:before {
			padding-bottom: 6.2rem;
			background-size: 76.7rem 6.3rem, 100%;
		}
	}
}

%transition-curve-out-bottom {
	@extend %transition-bottom;
	padding-bottom: 15rem;

	&:after {
		padding-top: 21.7rem;
		background-image: url(../../images/transitions/curve--out-bottom.svg), linear-gradient(#ffffff, #ffffff);
		background-repeat: no-repeat;
		background-size: 100% 21.8rem, 100%;
	}

	@include respond-to(small){
		padding-bottom: 6.2rem;

		&:after {
			padding-top: 6.2rem;
			background-size: 76.7rem 6.3rem, 100%;
		}
	}
}

%transition-top {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		display: block;
		top: -0.1rem;
		left: -0.1rem;
		right: -0.1rem;
		z-index: 11;
		background-clip: padding-box, content-box;
		background-position: center bottom;
		pointer-events: none;
		box-sizing: content-box;
	}
}

%transition-bottom {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		display: block;
		bottom: -0.2rem;
		left: -0.1rem;
		right: -0.1rem;
		z-index: 11;
		background-clip: padding-box, content-box;
		background-position: center top;
		pointer-events: none;
		box-sizing: content-box;
	}
}


.transition--none-top {
	@extend %transition-none-top;
}

.transition--none-bottom {
	@extend %transition-none-bottom;
}

.transition--tear-top {
	@extend %transition-tear-top;
}

.transition--curve-in-top {
	@extend %transition-curve-in-top;
}

.transition--curve-in-bottom {
	@extend %transition-curve-in-bottom;
}

.transition--curve-out-top {
	@extend %transition-curve-out-top;
}

.transition--curve-out-bottom {
	@extend %transition-curve-out-bottom;
}

.transition--small-top {
	@extend %transition-small-top;
}

.transition--small-bottom {
	@extend %transition-small-bottom;
}
