.section--rich-text{
	.post{
		margin: 0 auto;

		blockquote{
			border-left: 2px solid $accent-secondary;
			padding: 2rem 0 2rem 4rem;
			margin: 3rem auto;

			p{
				@extend %p-lead;
				color: $accent-primary;

				&:last-child{
					margin-bottom: 0;
				}
			}
		}

		ul{
			margin: 3rem auto;
		}
	}
}