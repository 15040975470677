.section--awards{
	.section__header{
		margin-bottom: 1rem;
	}
	p{
		@extend %p;
	}

	@include respond-to(medium){
		.grid--awards{
			margin-top: 2rem;
		}
	}
}

.award {
	text-align: center;
	text-align-last: center;
	@include display-flex;
	@include flex-direction(column);

	&__image {
		margin-bottom: 2rem;
		height: 10rem;

		img {
			display: block;
			margin: 0 auto;
			max-height: 100%;
			max-width: 100%;
		}
	}

	small {
		@extend %p-xsmall;
		font-weight: bold;
	}
}
