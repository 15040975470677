.section--tariff-search {
	.card {
		margin-left: auto;
		margin-right: auto;
	}

	.section__header {
		text-align: center;
		margin-bottom: 5rem;
	}

	.form {
		padding: 5rem 10rem;
		margin-bottom: 6rem;
		width: 76rem;
		max-width: 100%;

		.form__group {
			margin-bottom: 0;
		}

		@include respond-to(small) {
			padding: 4rem 6rem;
		}
	}

	.results {
		padding: 7rem 6rem;
		width: 96rem;
		max-width: 100%;

		.tariff-carousel {
			margin-top: 100px;
		}

		@include respond-to(medium) {
			.tariff-left {
				padding-right: 2rem !important;
			}

			.tariff-carousel {
				margin-top: 0;
			}
		}

		.tariff {
			margin-bottom: 4rem;

			header {
				border: 0.2rem solid $outline-contrast;
				border-radius: 0.6rem 0.6rem 0 0;
				padding: 2rem;
				border-bottom: 0;

				p {
					@extend %p-xsmall;
					margin-bottom: 0;
				}
			}

			.table {
				margin-top: 0;
				border-top: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;

				div:nth-child(2n+1) {
					background-color: $accent-tertiary;
				}

				div:nth-child(2n) {
					background-color: #ffffff;
				}
			}
		}
	}
}
