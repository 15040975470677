.carousel {
	width: 100%;
	max-width: 116rem;
	margin: 0 auto;

	&--nodraggable {
		.carousel__items {
			cursor: default;
		}
	}

	.carousel__items {
		@extend %list-unstyled;
		@include display-flex;
		@include flex-wrap(nowrap);
		@include align-items(center);
		cursor: grab;
		width: auto;

		&.is-moving {
			transition: transform .3s ease-in-out;
		}

		&.is-dragging {
			cursor: grabbing;
		}

		.carousel__item {
			@extend %light-background;
			text-align: center;
			color: $text;
			width: 100%;
			min-width: 100%;
			padding: 0 20%;
			opacity: .5;
			transition: opacity .3s ease-in-out, transform .3s ease-in-out;

			@include respond-to(xxsmall) {
				padding: 4rem;
			}

			* {
				pointer-events: none;
			}

			&.is-active {
				opacity: 1;
			}

			&.is-left {
				@include respond-to(xxsmall) {
				}
			}

			&.is-right {

				@include respond-to(xxsmall) {
				}
			}
		}

		.item__image {
			max-width: 6rem;
			margin: 2rem auto;
			position: relative;
			border-radius: 50%;
		}

		.item__copy {
			@extend %p-lead;
			color: $accent-primary;
		}

		.item__author {
			@extend %p-small;
			color: $accent-primary;

			span {
				font-weight: bold;
				padding-right: 1rem;
			}
		}
	}

	.carousel__progress {
		margin: 12rem 0 6rem;
		width: 100%;
		height: 0.2rem;
		background: $outline-contrast;

		span {
			transform-origin: left center;
			transition: transform .3s ease-in-out;
			display: block;
			width: 100%;
			height: 100%;
			background: $accent-secondary;
			transform: matrix(0, 0, 0, 1, 0, 0);
		}
	}

	.carousel__buttons {
		@extend %slider-buttons;
	}
}

.carousel--products,
.carousel--images {
	width: 100%;
	position: relative;

	.carousel__items {
		@include display-flex;
		@include align-items(stretch);
		@include flex-wrap(nowrap);
		cursor: grab;

		&.is-moving {
			transition: transform .3s ease-in-out;
		}

		&.is-dragging {
			cursor: grabbing;
			li {
				cursor: grabbing;
			}
		}

		.carousel__item {
			min-width: 0;
			padding: 0;
			opacity: 1 !important;
			position: relative;
			margin-right: 4rem;
			user-select: none;
			text-align: left;
			overflow: hidden;
			@include display-flex;
		}

		.is-link {
			cursor: pointer;
		}

		.item__title {
			@extend %heading-3;
			color: $accent-primary;
			margin-bottom: 1.9rem;
		}

		.item__subtitle {
			@extend %heading-6;
			margin-top: -1.4rem;
			margin-bottom: 1.9rem;
		}

		.item__copy {
			@extend %p-small;
			color: $text;
		}

		.item__image {
			border-top: 2rem solid transparent;
			margin: auto -4rem -3rem;
			width: auto;
			max-width: calc(100% + 8rem);
			border-radius: 0;
		}

		.item__icon {
			border-top: 2rem solid transparent;
			margin-top: auto;
			width: auto;
			max-width: 13rem;
			height: auto;
			max-height: 12rem;
			@include align-self(flex-end);
		}
	}

	.carousel__nav {
		display: none;
	}

	.carousel__buttons {
		position: absolute;
		bottom: calc(100% + 4rem);
		//right: 0;
		right: 4rem;

		@include respond-to(small) {
			display: none;
		}
	}
}

.carousel--products {
	.carousel__items {
		.carousel__item {
			@extend %shadow;
			@extend %shadow-hover;
			@extend %light-background;
			padding: 3rem 4rem 3rem;
			max-width: 100%;
			background: #ffffff;
			border-radius: 0.6rem;
			@include flex-direction(column);
			@include flex(0, 0, 36rem);

			@include respond-to(large) {
				width: 100%;
				padding: 2rem 4rem;
				min-width: 28.5rem;
			}

			@include respond-to(xsmall){
				padding: 2rem;
				min-width: 27rem;
				@include flex(0, 0, 27rem);
			}
		}
	}
}

.carousel--images {
	.carousel__items {
		.carousel__item {
			max-width: 100%;
			overflow: visible;
			@include flex(0, 0, 76rem);
			@include flex-wrap(wrap);
			@include justify-content-space-between;

			.item__image {
				@extend %rounded-image;
				margin: 0 0 5.8rem;
				border: none;
			}

			.item__header {
				max-width: 36rem;
				transform: translate(10rem);
				opacity: 0;
				transition: transform .35s ease-in-out,
				opacity .35s ease-in-out;
			}

			.item__title {
				@extend %heading-2;
			}

			.item__copy {
				@extend %p;
				transform: translate(10rem);
				opacity: 0;
				transition: transform .35s ease-in-out,
				opacity .35s ease-in-out;
				margin-left: 4rem;
				max-width: 36rem;
			}

			&.is-active {
				.item__copy,
				.item__header {
					transform: translate(0);
					opacity: 1;
				}
			}

			@include respond-to(small) {
				@include flex-direction(column);

				.item__copy,
				.item__header {
					max-width: none;
				}

				.item__header {
					margin-bottom: 2rem;
				}

				.item__copy {
					margin-left: 0;
				}
			}
		}
	}

	.carousel__progress {
		max-width: 76rem;

		@include respond-to(medium) {
			margin-top: 4rem;
		}

		@include respond-to(small) {
			margin-bottom: 2rem;
		}

		@include respond-to(xsmall) {
			margin-bottom: 0;
		}
	}
}

.slider-nav-standard {
	@extend %list-unstyled;
	@include display-flex;
	@include justify-content-center;
	margin: 3rem 0;

	li {
		cursor: pointer;

		width: 0.8rem;
		height: 0.8rem;
		background: rgba(#000000, .2);
		border-radius: 2.5rem;
		margin: 0.5rem 1rem;
		position: relative;
		transition: width .2s linear,
		background .2s linear;
		will-change: width, background;

		&.is-active {
			width: 2.5vw;
			background: $accent-secondary;

			@include respond-to(xxsmall) {
				width: 12vw;
			}

			@include respond-to(medium) {
				width: 7vw;
			}

			@include respond-to(large) {
				width: 5vw;
			}

			@include respond-to(xlarge) {
				width: 3vw;
			}
		}
	}
}
