*,
*:after,
*:before{
	box-sizing: border-box;
}

html {
	font-size: 0.625em;
	overflow-x: hidden;

	&.menu-open{
		overflow: hidden;
	}
}

body {
	font-family: 'Karla', sans-serif;
	color: $text;
	font-size: 1.6em;
	line-height: 1.43;
	font-weight: normal;
	min-height: 100vh;
	overflow-x: hidden;
	letter-spacing: -0.075rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@include headings {
	//might remove this
	margin-top: 0;

	a {
		text-decoration: none;
	}
}

h1, .h1 {
	@extend %heading-1;
}

h2, .h2 {
	@extend %heading-2;
}

h3, .h3 {
	@extend %heading-3;
}

h4, .h4 {
	@extend %heading-4;
}

h5, .h5 {
	@extend %heading-5;
}

h6, .h6 {
	@extend %heading-6;
}

p {
	margin-top: 0;
	letter-spacing: -0.075rem;
	margin-bottom: 3rem;
}

a {
	color: $text-secondary;
	text-decoration: underline;

	@include headings {
		text-decoration: none;
	}

	&:hover,
	&:focus {
		color: $accent-secondary;
	}
}

small {
	font-size: 1.4rem;
}

picture {
	display: block;
	max-width: 100%;

	img {
		display: block;
		max-width: 100%;
	}
}

ul {
	padding-left: 0;
}

fieldset {
	border: none;
	margin: 0;
}

.text--primary {
	color: $accent-primary !important;
}

.text--secondary {
	color: $text-secondary !important;

	@at-root {
		@include headings {
			&.text--secondary,
			.text--secondary {
				color: $heading-secondary !important;
			}
		}
	}
}

.background--primary {
	@extend %dark-background;
	background-color: $accent-primary !important;
}

.background--secondary {
	background-color: $accent-secondary !important;
	color: $accent-primary;
}

.background--tertiary {
	background-color: $accent-tertiary !important;
	color: $accent-primary !important;
}

.svg-icon {
	max-width: 100%;
	max-height: 100%;
	width: 2rem;
	height: 2rem;
	display: block;
}

.nav {
	@extend %list-unstyled;
	margin: 0 auto;
	@include display-flex;
	@include align-items(center);

	li {
		+ li {
			margin-left: 2rem;
		}

		a {
			text-decoration: none;
			font-weight: bold;
		}

		&:not(.btn) {
			a {
				color: $accent-primary;

				&:hover,
				&:focus {
					color: $text-secondary !important;
				}
			}
		}

		&.btn {
			a {
				color: inherit;

				&:hover,
				&:focus {
					color: inherit;
				}
			}
		}
	}

	&--small {
		li {
			a {
				font-size: 1.4rem;
				font-weight: bold;
			}
		}
	}
}

.nav--stacked {
	@include flex-direction(column);
	@include align-items(flex-start);

	li + li {
		margin-left: 0;
		margin-top: 1rem;
	}
}

.nav--arrows {
	li {
		font-weight: bold;
		@include display-flex;
		@include align-items(center);

		&:before {
			content: '';
			display: block;
			margin-right: 0.8rem;
			width: 1.7rem;
			height: 1.2rem;
			background: url(../../images/icons/icon--arrow.svg) no-repeat center;
			background-size: cover;
		}
	}
}

.nav--social {
	li + li {
		margin-left: 1rem;
	}

	a {
		display: block;
		height: 4rem;
		width: 4rem;
		border-radius: 50%;
		cursor: pointer;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		text-indent: 100%;
		overflow: hidden;

		&.facebook {
			background-image: url('../../images/icons/icon--social-facebook.svg');
		}

		&.twitter {
			background-image: url('../../images/icons/icon--social-twitter.svg');
		}

		&.linkedin {
			background-image: url('../../images/icons/icon--social-linkedin.svg');
		}

		&.youtube {
			background-image: url('../../images/icons/icon--social-youtube.svg');
		}

		&:hover{
			box-shadow: $box-shadow-secondary;
		}
	}
}

.list--features {
	li {
		@include display-flex;
		@include align-items(flex-start);

		&:before {
			content: '';
			background-image: url(../../images/icons/icon--tick.svg);
			display: block;
			width: 1.1rem;
			height: 1rem;
			margin-right: 2rem;
			margin-top: 0.5rem;
			@include flex(0, 0, 1.1rem);
		}
	}
}

.container {
	max-width: 120rem;
	width: 100%;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0 auto;

	&-small{
		max-width: 96rem;
	}
	&-smallest{
		max-width: 76.4rem;
	}

	.grid-bleed{
		margin-left: 0;
		margin-right: 0;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}
