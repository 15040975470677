.section--document-download {
	.container {
		@extend %transition-small-top;
		@extend %transition-small-bottom;
		padding: 0 10rem;

		&:before,
		&:after {
			height: 4rem;
		}

		@include respond-to(medium) {
			padding-left: 4rem;
			padding-right: 4rem;
		}

		@include respond-to(xsmall) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	.document {
		.document__content {
			@include display-flex;
			@include flex-direction(column);
			@include justify-content-center;
			padding-top: 8rem;
			padding-bottom: 8rem;

			.document__title {
				margin-bottom: 1rem;
			}

			.document__size {
				@extend %p-xsmall;
				@include align-self(center);
				margin-left: 2rem;
			}

			@include respond-to(small) {
				padding-top: 7rem;
			}
		}

		.document__image {
			box-shadow: none;
			max-width: 26rem;
			position: relative;
			z-index: 12;

			@include respond-to(small) {
				margin: auto;
			}
		}
	}
}
