.section--product {
	.product {
		.product__image {
			position: relative;
			z-index: 12;

			@include respond-to(small) {
				margin: 0 auto 4rem;
				max-width: 40rem;
			}
		}

		.product__title {
			margin-bottom: 1.5rem;
		}

		.product__copy {
			@extend %p;
			color: #968DA0;
			margin-bottom: 3rem;
		}

		.product__features {
			margin: 3rem auto 1.5rem;
			//column-count: 2;
			display: flex;
			flex-wrap: wrap;

			> li {
				width: 50%;
				display: inline-flex;
				margin-bottom: 1.5rem;

				@include respond-to(xsmall){
					width: 100%;
				}
			}
		}
	}
}
