.section {
	.section__inner {
		padding-top: 6rem;
		padding-bottom: 6rem;

			@include respond-to(small){
				.order-3 + .order-2{
					margin-bottom: 3rem;
				}
				.order-1 + .order-2{
					margin-top: 3rem;
				}
			}

		@at-root {
			.section--pad-top-sm .section__inner {
				padding-top: 3rem;

				@include respond-to(small) {
					padding-top: 1.5rem;
				}
			}

			.section--pad-top-lg .section__inner {
				padding-top: 12rem;

				@include respond-to(small) {
					padding-top: 6rem;
				}
			}

			.section--pad-top-none .section__inner {
				padding-top: 0;

				@include respond-to(small) {
					padding-top: 0;
				}
			}

			.section--pad-bot-sm .section__inner {
				padding-bottom: 3rem;

				@include respond-to(small) {
					padding-bottom: 1.5rem;
				}
			}

			.section--pad-bot-lg .section__inner {
				padding-bottom: 12rem;

				@include respond-to(small) {
					padding-bottom: 6rem;
				}
			}
			.section--pad-bot-none .section__inner {
				padding-bottom: 0;

				@include respond-to(small) {
					padding-bottom: 0;
				}
			}
		}

		@include respond-to(small) {
			padding-top: 3rem;
			padding-bottom: 3rem;
		}
	}

	.section__header {
		margin-bottom: 4rem;

		@include headings {
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		p {
			@extend %p;
			max-width: 56rem;
			margin-left: auto;
			margin-right: auto;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.section--default {
		@extend %light-background;
	}

	&.section--primary {
		@extend %dark-background;
		background-color: $accent-primary;
	}

	&.section--secondary {
		background-color: $accent-secondary;
		color: $accent-primary;
	}

	&.section--tertiary {
		background-color: $accent-tertiary;

		@include headings {
			color: $accent-primary;
		}
	}

	&.section--bg-image {
		position: relative;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		.container {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				//z-index: -1;
			}

			* {
				position: relative;
				z-index: 1;
			}
		}

		&.section--primary .container:before {
			background-color: rgba(37, 13, 71, 0.7);
		}
	}

	&.section--overlap-top{
		.media-wrapper {
			margin-top: -15rem;
			position: relative;
			z-index: 12;

			@include respond-to(medium){
				margin-top: -10rem;
			}
			@include respond-to(small){
				margin-top: 0;
			}
		}
	}

	&.section--overlap-bottom{
		.media-wrapper {
			margin-bottom: -20rem;
			position: relative;
			z-index: 12;

			@include respond-to(large){
				margin-bottom: -15rem;
			}
			@include respond-to(medium){
				margin-bottom: -10rem;
			}
			@include respond-to(small){
				margin-bottom: 0;
			}
		}

		+ .section {
			padding-top: 20rem;

			@include respond-to(medium) {
				padding-top: 12rem;
			}

			@include respond-to(small) {
				padding-top: 6rem;
			}
		}
	}

	@include respond-to(medium) {
		.section__header {
			margin-bottom: 3rem;
		}
	}

	@include respond-to(xsmall) {
		.section__header {
			margin-bottom: 2rem;
		}
	}
}

@import "sections/accordions";
@import "sections/calculator";
@import "sections/carousel";
@import "sections/case-studies";
@import "sections/copy-grid";
@import "sections/usps";
@import "sections/cta";
@import "sections/faq";
@import "sections/feature-callout";
@import "sections/media-copy";
@import "sections/overlap-media";
@import "sections/product";
@import "sections/staggered-grid";
@import "sections/awards";
@import "sections/central";
@import "sections/stats";
@import "sections/document-download";
@import "sections/locations";
@import "sections/rich-text";
@import "sections/static-form";
@import "sections/table-copy";
@import "sections/tariff-search";
@import "sections/contact";
@import "sections/broadband-search";
@import "sections/customer-satisfaction";

