#page-header {
	height: 100%;

	@include respond-to(small){
		min-height: 0;
	}

	&.affixed {
		position: relative;
		z-index: 100;

		#main-nav {
			@extend %light-background;
			position: fixed;
			background-color: white;
			width: 100%;
			top: 0;
			left: 0;
			padding: 1rem 0;
			transition: background-color .2s linear;
			transform: translateY(-100%);
			margin-top: 0;
			box-shadow: 0 1rem 1.5rem rgba(0,0,0,.05);
			animation: slideDown .19s linear 0s 1 forwards;

			@at-root {
				@keyframes slideDown {
					0% {
						transform: translateY(-100%);
					}
					100% {
						transform: translateY(0);
					}
				}
			}

			//.btn--outline {
			//	border-color: $accent-primary;
			//	color: $accent-primary;
			//}

			#logo {
				#text {
					fill: $accent-primary;
				}
			}

			@at-root {
				.menu-open & {
					@extend %dark-background;
					background-color: $accent-primary;

					#logo {
						#text {
							fill: white;
						}
					}
				}
			}
		}

		+ main{
			margin-top: 7.1rem;

			@include respond-to(medium){

				margin-top: 4.1rem;
			}
		}
	}
}

[class^="header"]{
	position: relative;

	// Maybe Remove
	padding-bottom: 13rem;

	#navs-wrapper{
		padding: 2rem 0 2rem;
		z-index: 20;
		position: relative;
		transition: background-color .2s linear;

		@at-root {
			.menu-open & {
				background-color: $accent-primary !important;

				#logo {
					#text {
						fill: white !important;
					}
				}
			}
		}
	}

	#logo {
		line-height: 0;
	}

	.trustpilot{
		margin-top: 3rem;
	}

	.breadcrumbs{
		margin-top: 3rem;
		margin-bottom: 5rem;

		a{
			text-decoration: none !important;
		}

		img{
			margin: 0 0.4rem;
			position: relative;
			top: 0.2rem;
		}

		//> span > span > *:last-child{
		//	display: none;
		//}
	}

	.hero{
		> .container{
			z-index: 2;
		}

		&:after{
			content: '';
			width: 100%;
			height: 13rem;
			background-image: url("../../images/transitions/curve--white.svg");
			background-size: 100% 13rem;
			background-repeat: no-repeat;
			background-position: center top;
			position: absolute;
			bottom: -0.2rem;
			z-index: 1;

		}

		@include respond-to(small) {
			padding-bottom: 8rem;

			&:after{
				content: "";
				height: 12.4rem;
				width: 100%;
				background-size: 767px 12.5rem;
				background-position: center top;
				background-repeat: no-repeat;

				@at-root {
					body:not(.home) & {
						height: 12rem;
					}
				}
			}

			&.hero--has-buttons{
				padding-bottom: 2.5rem;

				&:after{
					content: "";
					height: 17rem;
					width: 100%;
					background-size: 767px 12.5rem, 100% calc(100% - 12.4rem);
					background-position: center top, 100% 100%;
					background-repeat: no-repeat;

					@at-root {
						body:not(.home) & {
							height: 12rem;
						}
					}
				}
			}
		}

		@include respond-to(xxsmall) {
			padding-bottom: 6rem;

			&:after{
				content: "";
				height: 7rem;
				width: 100%;
				background-size: 767px 7.1rem;
				background-position: center top;
				background-repeat: no-repeat;

				@at-root {
					body:not(.home) & {
						height: 7rem;
					}
				}
			}
		}
	}

	&.header--curve-tertiary .hero{
		&:after{
			background-image: url('../../images/transitions/curve--grey.svg');
		}

		&.hero--has-buttons:after{
			@include respond-to(small) {
				background-image: url("../../images/transitions/curve--grey.svg"), linear-gradient($accent-tertiary, $accent-tertiary);
			}
		}
	}

	&.header--curve-primary .hero{
		&:after{
			background-image: url('../../images/transitions/curve--purple.svg');
		}

		&.hero--has-buttons:after{
			@include respond-to(small) {
				background-image: url("../../images/transitions/curve--purple.svg"), linear-gradient($accent-primary, $accent-primary);
			}
		}
	}

	.hero{
		&:after{
			background-image: url("../../images/transitions/curve--white.svg");
		}

		&.hero--has-buttons:after{
			@include respond-to(small) {
				background-image: url("../../images/transitions/curve--white.svg"), linear-gradient(#fff, #fff);
			}
		}
	}
}

.header {
	background-color: $accent-tertiary;
	color: $accent-primary;

	#logo svg #text{
		fill: $accent-primary;
	}

	.breadcrumbs{
		a{
			color: $accent-primary;
		}

		span{
			color: $text;
		}
	}

	.col-banner {
		background-color: #2CCA73;
		z-index: 20;

		.banner-image {
			margin: 0 auto;
			max-width: 140px;
			height: auto;
		}

		.banner-text {
			color: #250D47;
			font-weight: 600;
			margin: 0.7rem;
		}

		.banner-text-one {
			font-size: 3rem;
		}

		.banner-text-two {
			font-size: 2.5rem;
			color: #fff;
		}

		.banner-text-three {
			font-size: 1.7rem;
			margin-top: 20px;
		}
	}

	.col-banner-text-holder {

		text-align: center;

		@media only screen and (max-width: 980px)  {
			width: 100%;
		}
	}

	.promotion-banner {
		position: sticky;
		z-index: 10;
		line-height: initial;

		.banner-stroke {
			min-height: 20px;
			display: block;
			background-size: cover;
			background-repeat: no-repeat;
			width: 100%;
		}

		.banner-stroke.top {
			background-image: url('../../images/home-banner-top.svg');
			margin-bottom: -1px;
		}

		.banner-stroke.bottom {
			background-image: url('../../images/stroke.svg');
			margin-top: -1px;
		}

		.banner-link {
			display: contents;
			text-decoration: none;
		}
	}

	#navs-wrapper{
		background-color: white;

		#menu-primary-navigation {
			li {
				&.btn--outline{
					border-color: $outline-contrast;
				}
			}
		}
	}

	&--large{
		padding-bottom: 0;
		overflow: hidden;


		&.affixed{
			overflow: visible;
		}

		.hero{
			padding-bottom: 20rem;
			background-size: contain;
			background-position: center right;

			@include respond-to(small) {
				padding-bottom: 8rem;
				background-size: auto 35rem;
				background-position: right 19rem;

				&.hero--has-buttons{
					padding-bottom: 2.5rem;

					.hero__cta{
						margin-bottom: 5rem;
					}
				}

				.breadcrumbs{
					margin-top: 0;
					margin-bottom: 38rem;
				}
			}

			@include respond-to(xsmall) {
				background-size: auto 23rem;
				background-position: right 19rem;

				.breadcrumbs{
					margin-top: 0;
					margin-bottom: 27rem;
				}
			}
		}

		@at-root{
			.home & {
				.hero{
					@include respond-to(small) {
						padding-bottom: 8rem;
						background-size: auto 70%;
						background-position: top right;

						&.hero--has-buttons{
							padding-bottom: 2.5rem;

							.hero__cta{
								margin-bottom: 0;
							}
						}
					}
				}

				@include respond-to(small){
					+ main section:first-child{
						.section__inner{
							padding-top: 0.5rem;
						}
					}
				}
			}
		}
	}

	&--transparent {
		background-color: white;

		#logo {
			svg {
				#text {
					fill: $accent-primary;
				}
			}
		}

		#navs-wrapper {
			background-color: transparent;

			#misc-nav li a {
				color: inherit;
			}

			#menu-primary-navigation {
				li {
					&.btn--outline {
						border-color: $accent-primary;
					}
				}
			}
		}
	}

	&.header--primary {
		@extend %dark-background;
		background-color: $accent-primary;

		#navs-wrapper {
			background-color: transparent;
		}

		.breadcrumbs {
			a, span{
				color: white;
			}
		}

		#logo svg #text {
			fill: #ffffff;
		}
	}

	&.header--small{
		padding-bottom: 0;

		> .container{
			position: relative;
			z-index: 2;

		}

		p {
			@extend %p;
			color: $text-lighter;
		}

		.buttons{
			margin-bottom: 5rem;

			.btn{
				margin-bottom: 1rem;
			}
		}

		&:after{
			content: "";
			height: 13rem;
			width: 100%;
			background-image: url('../../images/transitions/curve2--white.svg');
			//background-size: 100% 13rem;
			background-size: calc(100% + 1px) auto; // This one looks better than above...

			background-repeat: no-repeat;
			background-position: center top;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

		&.header--curve-tertiary:after{
			background-image: url('../../images/transitions/curve2--grey.svg');
		}

		&.header--curve-primary:after{
			background-image: url('../../images/transitions/curve2--purple.svg');
		}

		@include respond-to(medium){
			padding-bottom: 15rem;
		}
	}

	&--singular {
		background-color: $accent-tertiary;
		padding-bottom: 0;

		> .container{
			min-height: 31.5rem;
		}

		> * {
			z-index: 3;
			position: relative;
		}

		#logo svg #text {
			fill: #3B3741;
		}

		#navs-wrapper{
			background-color: transparent;

			#menu-primary-navigation {
				li {
					a {
						color: #3B3741;
					}

					&.btn--outline{
						border-color: $outline-contrast;
						color: #3B3741;
					}
				}
			}
			#menu-misc-header-nav li a {
				color: #3B3741;
			}
		}

		@include respond-to(small){
			> .container{
				min-height: 0;
			}

			.breadcrumbs{
				margin-bottom: 8rem;
			}

			&:after{
				height: 13rem;
				background-size: 76.7rem 6.8rem, 100% calc(100% - 6.7rem);
				//background-position: left top;
			}
		}

		@at-root {
			.single-post--primary &{
				padding-bottom: 40rem;

				.breadcrumbs{
					margin-bottom: 10rem;
				}

				+ #page-content{
					margin-top: -40rem;
				}

				> .container{
					min-height: 15rem;
				}

				&:after{
					background-image: url('../../images/transitions/curve--singular-purple.svg'), linear-gradient($accent-primary, $accent-primary);
					background-size:  100% 12.5rem, 100% calc(100% - 12.4rem);
					background-position: center top, 100% 100%;
				}

				@include respond-to(small){
					.breadcrumbs{
						margin-bottom: 5rem;
					}

					&:after{
						height: 30rem;
						background-size: 76.7rem 6.8rem, 100% calc(100% - 6.7rem);
					}
				}
			}
		}

		@at-root {
			.page-template-page-templatessimple-php.single-post--primary &{
				padding-bottom: 30rem;

				.breadcrumbs{
					margin-bottom: 5rem;
				}

				+ #page-content{
					margin-top: -5rem;
				}

				@include respond-to(small){
					padding-bottom: 32rem;

					+ #page-content{
						margin-top: -20rem;
					}
				}
			}
		}
	}

	&--blog{
		.list--cats {
			margin-bottom: 3rem;

			a {
				text-decoration: none;
			}
		}

		&:after{
			content: "";
			height: 13rem;
			width: 100%;
			background-image: url('../../images/transitions/curve--white.svg');
			background-size: 100% 13.1rem;

			background-repeat: no-repeat;
			background-position: center top;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;

			@include respond-to(xsmall){
				background-size: 60rem 13rem;
				background-position: left top;
			}
		}

		@include respond-to(large){
			padding-bottom: 7rem;
		}
	}
}

#misc-nav {
	position: relative;
	z-index: 8;

	#menu-misc-header-nav {
		@include justify-content-end;

		li {
			a {
				color: #C7C5CC;
				will-change: color;
				transition: color .1s ease-in-out;

				@at-root {
					.header--primary & {
						color: #C7C5CC;

						&:hover,
						&:focus {
							color: $accent-secondary;
						}
					}
				}
			}
		}
	}
}

#main-nav {
	width: 100%;
	margin-top: 2rem;
	position: relative;
	z-index: 10;
	background-color: transparent;

	> .container{
		display: flex;
		align-items: center;
		position: relative;
	}

	#menu-primary-navigation {
		margin-right: 0;

		li {
			a {
				font-weight: bold;
				font-size: 1.6rem;
				margin-right: 0;
			}

			&:not(.btn) {
				a {
					line-height: 5rem;

					@extend %nav-item-chevron;

					@at-root .header--secondary & {
						background-color: $accent-primary;
					}
				}

				&.current_page_item a {
					&:before,
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}

	@include respond-to(medium){
		margin-top: 0;
	}
}

#mobile-links{
	margin-right: 0;
}

#nav-trigger{
	@extend %btn;
	border: 0.2rem solid $accent-secondary;
	padding: 1rem;
	min-width: 0;

	span{
		display: none;
		vertical-align: middle;
	}

	i{
		display: inline-block;
		vertical-align: middle;
		height: .2rem;
		width: 1.6rem;
		background-color: $accent-primary;
		position: relative;
		margin-top: 0.7rem;
		margin-bottom: 0.7rem;
		transition: all .2s linear;

		&:before,
		&:after{
			content: '';
			height: .2rem;
			width: 1.6rem;
			left: 0;
			position: absolute;
			background-color: inherit;
			transition: all .2s linear;
		}

		&:before{
			position: absolute;
			top: -.4rem;
		}
		&:after{
			position: absolute;
			bottom: -.4rem;
		}
	}

	@at-root {
		.menu-open & {
			padding: 1rem 2rem;
			background-color: transparent;
			border-color: white;

			i{
				background-color: transparent;

				&:before,
				&:after{
					background-color: white;
					width: 1.2rem;
				}

				&:before{
					transform: rotate(45deg);
					transform-origin: 2px 5px;
				}

				&:after{
					transform: rotate(-45deg);
					transform-origin: 2px -3px;
				}
			}
			span{
				display: inline-block;
				color: white;
				margin-left: 1rem;
			}
		}
	}
}

.menu-open .login-link{
	display: none;
}

#mobile-nav{
	@extend %dark-background;
	z-index: 9;
	height: 100vh;
	position: fixed;
	width: 100%;
	max-width: 37.5rem;
	background-color: $accent-primary;
	right: 0;
	top: 0;
	transform: translateX(100%);
	padding: 8.1rem 2.5rem 2rem;
	transition: all ease-out 0.35s;

	@at-root {
		.menu-open &{
			transform: translateX(0);
		}
	}

	#mobile-menu-primary-navigation{
		flex-direction: column;
		margin-bottom: 3rem;

		li{
			display: block;
			width: 100%;

			&:not(.btn){
				padding: 2rem 0;

				a{
					color: white;
					font-size: 1.6rem;
					font-weight: bold;
				}

				&:first-child {
					padding-top: 1rem;
				}
			}

			+ li{
				margin-left: 0;

				&:not(.btn){
					border-top: 0.2rem solid #514068;
				}
			}

			&.btn--outline{
				display: none;
			}
		}
	}

	#mobile-menu-misc-header-nav{
		li{
			a{
				color: white;
			}
			+ li {
				margin-top: 2.5rem;
			}
		}
	}

	> .grid{
		margin-left: -$reflex-grid-spacing;
		margin-right: -$reflex-grid-spacing;
	}
}

.hero{
	display: flex;
	align-items: flex-start;
	margin-top: -13.2rem;
	padding-top: 13.2rem;
	//padding-top: 12.2rem;
	height: calc(100% + 2rem);

	&__title{
		line-height: 1;
		font-size: 9rem;
		margin-bottom: 5rem;

		@include respond-to(small){
			font-size: 5rem;
		}

		@at-root {
			body:not(.home) &{
				margin-bottom: 3rem;
			}
		}

		@at-root {
			.home & {
				font-size: 12rem;

				@include respond-to(small){
					font-size: 9.5rem;
					max-width: 300px;
				}

				@include respond-to(xsmall) {
					font-size: 7rem;
				}
			}
		}

		@include respond-to(xsmall) {
			font-size: 4.8rem;
			letter-spacing: -0.363rem;
			line-height: 6rem;
			max-width: 300px;
		}
	}

	&__subtitle{
		@include headings{
			margin-bottom: 3rem;
		}

		p{
			@extend %p-lead;
			color: $text;
		}

		@include respond-to(xsmall) {
			@include headings{
				font-size: 2.2rem;
				line-height: 3rem;
				max-width: 35rem;
			}
		}
	}

	&__buttons{
		.btn + .btn{
			margin-left: 1rem;
		}

		@include respond-to(xsmall){

			.btn {
				padding: 1.5rem 3rem;
				min-width: 0;
			}
		}
	}

	&__cta{
		max-width: 30rem;
		margin-right: 0;
		margin-left: auto;
		text-align: center;
		text-align-last: center;

		.btn{
			position: relative;
			z-index: 10;
		}

		@include respond-to(medium){
			max-width: 20rem;

			.btn--xlg {
				padding: 2.1rem 2rem;
				border-radius: 3rem;
				font-size: 1.7rem;
				min-width: 20rem;
			}
		}

		@include respond-to(small){
			max-width: 100%;
			text-align: left;
			text-align-last: left;
			margin-top: 5rem;
		}
	}

	[id^="jarallax-container-"]{
		//max-width: 120rem;
		//max-width: 144rem;
		left: 0;
		right: 0;
		margin: auto;
		height: calc(100% + 10rem) !important;
		margin-top: -5rem;
		margin-bottom: -5rem;

		@include respond-to(small){
			display: none !important;
		}
	}

	@include respond-to(medium){
		padding-top: 11.2rem;
	}

	@include respond-to(small){
		padding-top: 15rem;
		background-size: auto 70%;
		background-position: top right;
	}

	//@media screen and (max-width: 480px){
	//	[id^="jarallax-container-"]{
	//		display: none;
	//	}
	//}
}

.learn-more{
	text-align: center;
	text-align-last: center;
	margin-top: 2rem;

	span{
		font-family: "Karla";
		color: white;
		font-size: 3rem;
	}

	img{
		display: block;
		margin: 1rem auto 0;
	}

	@include respond-to(small){
		display: none;
	}
}

.trustpilot{
	display: flex;
	align-items: flex-end;

	.trustpilot-widget{
		margin-right: 1rem;
	}

	p{
		@extend %p;
		margin: 0;
		flex: 16rem 0 1;
	}
}

.jarallax {
	position: relative;
	z-index: 0;
	background-repeat: no-repeat;
	background-position: center bottom;

	.jarallax-img {
		position: absolute;
		top: 19rem;
		right: 0;
		left: auto !important;
	    width: auto !important;
	    padding-left: 40px;

		img{
			position: relative;
			right: 0;
			

		}
	}
}

.home .jarallax .jarallax-img{
	top: 0;
}
