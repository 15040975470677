.section--locations {
	.section__header {
		margin-bottom: 0;
	}
}

.location {
	@extend %shadow;
	border: 0.2rem solid $outline-contrast;
	border-radius: 0.6rem;
	margin-bottom: 2rem;
	position: relative;
	@include flex-grow(1);
	@include display-flex;
	@include flex-direction(column);

	@include respond-to(small) {
		border: none;
		border-bottom: 0.1rem solid $outline-contrast;
		margin: 0;
		border-radius: 0;
		transition: border-color .2s ease-in-out;
	}

	.location__name {
		padding: 3.5rem 3.8rem 0;
		margin-bottom: -2rem;
		position: relative;

		@include respond-to(small) {
			cursor: pointer;
			margin-bottom: 0;
			padding: 1.5rem 1.5rem 1.5rem 3.5rem;
		}

		&:after,
		&:before {
			display: none;
			content: '';
			position: absolute;
			width: 1.2rem;
			height: 0.2rem;
			background: $accent-primary;
			top: 50%;
			left: 0;
			transition: transform .1s ease-in-out;

			@include respond-to(small) {
				display: block;
			}
		}

		&:after {
			transform: translate(0, -20%) rotate(90deg) scale(1);
		}
	}

	@include respond-to(small) {
		margin-bottom: -2 * $reflex-grid-spacing;
	}

	.location__content {
		@include flex-grow(1);

		@include respond-to(small) {
			display: none;
		}

		.location__wrap {
			height: 100%;
			@include display-flex;
			@include flex-direction(column);
			@include justify-content-space-between;

			@include respond-to(small) {
				@include flex-direction(row);
			}

			@include respond-to(xxsmall) {
				@include flex-direction(column);
			}
		}
	}

	&.is-active {
		@include respond-to(small) {
			border-color: transparent;
		}

		.location__name {
			&:after {
				transform: translate(0, -20%) rotate(90deg) scale(0,1);
			}
		}
	}

	.location__info {
		margin: 0;
		padding: 2rem;
		@include flex-grow(1);

		@include respond-to(small) {
			padding: 2rem;
		}
	}

	.location__address,
	.location__phone {
		@extend %p-small;

		a:not(.btn) {
			color: inherit;
		}

		span {
			display: block;
		}

		@include respond-to(small) {
			padding: 0;
		}
	}

	.location__map {
		position: relative;
		@include align-self(center);

		.map {
			width: 100%;
		}

		.marker {
			position: absolute;
			left: 50%;
			bottom: 50%;
			transform: translate(-50%, 20%);
		}

		.location__directions {
			position: absolute;
			bottom: 2rem;
			left: 0;
			right: 0;
			@include display-flex;
			@include justify-content-center;
		}
	}
}
