.section--broadband-search {
  .search-card {
    margin: 0 auto;
    max-width: 100%;
    padding: 5rem 10rem;
    width: 76rem;
  }

  .postcode-lookup {
    margin: 0 auto;
    display: block;
  }

  .postcode-text {
    color: #250d47;
    text-align: center;
  }

  .laptop-speedo {
    margin: 5px auto;
    max-width: 200px;
    display: block;
  }

  .search-box .form__group {
    margin-top: 20px;
  }

  .searchBroadbandButton {
    margin: 20px auto 0;
    display: none;
  }
}

.tariffInformation {
  h2 {
    color: #250d47;
  }

  .virgin-logo {
    max-width: 200px;
  }
}

#broadbandCard {
  overflow: hidden;

  .joinButton {
    margin-top: 20px;
  }

  .loading {
    display: none;
    margin: 0 auto;
  }

  .tariffInformation {
    display: none;
  }

  margin-top: 6rem;
}

@media only screen and (max-width: 768px) {
  #broadbandCard {
    padding: 1rem;
  }

  .section--broadband-search .search-card {
    padding: 5rem 4rem;
  }
}