font[size="1"] {
	margin-left: auto;
	margin-right: auto;
	align-self: center;
	justify-self: center;

	& + br {
		display: none;
	}
}

.xdebug-error {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	display: block;
	color: #000000;
	font-size: 1.4rem;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 120rem;
	border-radius: 0;
	border: none;
	margin-bottom: 2rem;
	line-height: normal;
	letter-spacing: normal;
	word-spacing: normal;

	a {
		color: inherit;
		border: none;
		text-decoration: underline;

		&:before,
		&:after {
			content: none;
		}
	}

	td {
		padding: 0.05rem 0.5rem;
	}

	th {
		padding: 0.15rem 0.5rem;
	}

	pre {
		margin: 0;
		padding: 0.15rem 0.5rem;
	}

	& + br {
		display: none;
	}
}