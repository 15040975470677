.section--product-carousel,
.section--image-carousel {
	.section__header {
		margin-bottom: 0;
	}
}

.section--product-carousel {
	.section__inner {
		position: relative;
		z-index: 20;

		background-image: url("../../../images/scratch--horizontal.svg");
		background-position: right 5.5rem;
		background-size: 53.9rem 16.2rem;
		background-repeat: no-repeat;
	}

	.grid {
		margin-top: -$reflex-grid-spacing;
		margin-bottom: -$reflex-grid-spacing;
	}

	.carousel__progress {
		margin-bottom: 0;
	}

	&:after {
		height: 35%;
	}
}

.section--image-carousel {
	@extend %transition-curve-in-top;

	&:after {
		content: '';
		position: absolute;
		display: block;
		background-color: #ffffff;
		height: 54rem;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;

		@include respond-to(small) {
			//height: 30rem;
			height: 50%;
		}
	}

	.section__inner{
		background-image: url("../../../images/scratch--diagonal.svg");
		background-position: calc(100% - 6rem) 7.5rem;
		background-size: 38.5rem 17.9rem;
		background-repeat: no-repeat;

		@include respond-to(small){
			background-position: calc(100% + 10rem) 6rem;
			background-size: 23.5rem 11.9rem;
		}
	}

	.carousel {
		z-index: 2;
	}

	.carousel__progress {
		margin-top: 6rem;
		margin-bottom: 0;
	}
}
