.section--feature-callout {
	padding-left: 2rem;
	padding-right: 2rem;

	&:before,
	&:after {
		height: 6rem;
	}

	.container {
		@extend %transition-small-top;
		@extend %transition-small-bottom;
		padding: 7rem 10rem 5rem;
		background-image: url(../../../images/feature-callout-left.svg), url(../../../images/feature-callout-right.svg);
		background-position: left top, right bottom;
		background-repeat: no-repeat;

		@include respond-to(small) {
			background-image: url(../../../images/feature-callout-mobile-left.svg), url(../../../images/feature-callout-mobile-right.svg);
		}

		@include respond-to(xsmall) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}

	p {
		@extend %p-lead;

		&:last-child {
			margin-bottom: 0;
		}
	}

	small {
		@extend %p-xsmall;
		font-weight: bold;
	}
}
