.post {
	padding-top: 6rem;
	padding-bottom: 12rem;

	@extend %post-content;

	@include headings{
		color: $accent-primary;
	}
}

.single-post{
	&__header{
		text-align: center;
		text-align-last: center;

		time {
			font-size: 1.2rem;
			line-height: 1.4rem;
			font-weight: bold;
			color: $text;
			letter-spacing: 0.2px;
			display: block;
		}

		h1 {
			margin-bottom: 0;
		}
	}

	&__video{
		@extend %embed-responsive;
	}

	&__image{
		position: relative;

		&:after{
			content: "";
			height: 9rem;
			width: 100%;
			background-image: url('../../images/transitions/curve--image.svg');
			background-size: 100% auto;

			background-repeat: no-repeat;
			background-position: center calc(100% + 1px);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;

		}
	}

	&__excerpt,
	&__content{
		max-width: 76rem;
		margin: 6rem auto 0;
		display: block;
	}

	&__excerpt{
		p{
			font-size: 2.2rem;
			line-height: 3.1rem;
			color: $accent-primary;

			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	&__content{
		.wp-block-image,
		ul,
		.wp-block-quote {
			margin-top: 6rem;
			margin-bottom: 6rem;
		}
	}

	&__related{
		position: relative;
		margin: 0 auto;
		color: #250D47;
		padding: 13rem 0 4rem;

		> .container{
			z-index: 2;
			position: relative;
		}

		h2{
			margin-bottom: 2rem;
		}

		.grid--posts{
			margin-top: 3rem;
		}

		&:before {
			content: '';
			height: 100%;
			width: 100vw;
			background-image: url("../../images/transitions/curve--testimonial-grey.svg"), linear-gradient($accent-tertiary, $accent-tertiary);
			background-size: 100% 13.8rem, 100% calc(100% - 13.8rem);
			background-position: center top, 100% 100%;
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			left: calc( 0px - ((100vw - 100%) / 2) );
			z-index: 1;
		}
	}

	&__author{
		margin-top: 6rem;
		font-size: 1.2rem;
		line-height: 1.4rem;
		font-weight: bold;
		letter-spacing: 0.1px;

		> span{
			display: block;
		}

		.title{
			color: $text-stand;
			margin-bottom: 1rem;
		}
	}

	&--primary{
		background-color: $accent-primary;
		color: white;

		@include headings{
			color: $accent-secondary;
		}

		.grid--usps.grid--usps-stats > div,
		.wp-block-quote p,
		.single-post__excerpt p{
			color: white;
		}

		.single-post__related,
		.single-post__header{
			@include headings{
				color: $accent-primary;
			}
		}

		.single-post__author{
			.title{
				color: white;
			}
		}

		.single-post__image{
			&:after {
				background-image: url('../../images/transitions/curve--image-purple.svg');
			}
		}

		.wp-block-testimonial {
			position: relative;
			margin: 10rem auto;

			&__quote{
				color: $accent-primary;
			}

			&:before {
				background-image: url("../../images/transitions/curve--testimonial-white.svg"), linear-gradient(#fff, #fff);
			}
			&:after {
				background-image: url("../../images/transitions/small--bottom-primary.svg");
			}
		}
	}
}

.page-template-simple{
	.post{
		padding-top: 3rem;
	}
}

.wp-block-image{
	position: relative;
	line-height: 0;

	img {
		border-radius: 0.5rem;
		overflow: hidden;
	}

	figcaption {
		position: absolute;
		background-color: $outline-contrast;
		font-size: 1.2rem;
		font-weight: bold;
		color: $text-stand;
		left: 0;
		bottom: 0;
		padding: 0.9rem 1.4rem;
		display: block;
		margin: 0;
		letter-spacing: 0.1px;
		line-height: 1;
	}
}

.wp-block-quote{
	border-left: 2px solid $accent-secondary;
	margin: 0;
	padding: 2rem 0 2rem 4rem;

	p{
		@extend %p-lead;
		color: $accent-primary;
	}
}

.wp-block-embed{
	margin: 6rem auto 10rem;

	@extend %shadow;

	&__wrapper {
		@extend %embed-responsive;
	}
}

.wp-block-testimonial{
	position: relative;
	margin: 10rem auto;

	&:before{
		content: '';
		height: 100%;
		width: 100vw;
		background-image: url("../../images/transitions/curve--testimonial.svg"), linear-gradient($accent-primary, $accent-primary);
		background-size: 100% 13.8rem, 100% calc(100% - 13.8rem);
		background-position: center top, 100% 100%;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: calc( 0px - ((100vw - 100%) / 2) );
		z-index: 1;
	}

	&:after {
		content: '';
		position: absolute;
		z-index: 2;
		height: 3.1rem;
		width: 100vw;
		bottom: -1px;
		left: calc( 0px - ((100vw - 100%) / 2) );
		background-image: url("../../images/transitions/small--bottom.svg");
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center bottom;
	}

	&__quote{
		z-index: 3;
		position: relative;
		padding-top: 15.6rem;
		padding-bottom: 15.1rem;
		margin: 0;
		width: 100%;
		max-width: 56rem;
		color: white;

		p{
			font-size: 2.2rem;
			line-height: 3.1rem;

			&:before{
				content: '';
				display: block;
				margin-bottom: 1rem;
				background-image: url("../../images/speech-mark.svg");
				background-size: 1.9rem 1.6rem;
				background-repeat: repeat;
				height: 1.6rem;
				width: 1.9rem;
			}

			&:after{
				content: " \201D";
			}
		}

		cite{
			font-style: normal;
			margin-top: 2.6rem;

			strong{
				margin-right: 1rem;
			}
		}

		&:before{
			content: "";
			height: 37.2rem;
			width: 27.4rem;
			background-size: 27.4rem 37.2rem;
			background-image: url("../../images/scratch.svg");
			background-position: center;
			position: absolute;
			top: 0;
			bottom: 0;
			left: calc( 0px - ((100vw - 100%) / 4) );
			margin: auto;
		}
	}


	&:last-child{
		margin-bottom: -12rem;

		.wp-block-testimonial__quote{
			padding-bottom: 12.1rem;
		}

		&:after {
			background-image: none;
		}
	}
}

