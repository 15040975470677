/*
@font-face {
	font-family: 'Mandarin';
	src: url('../../fonts/mandarin-whispers.woff2') format('woff2'),
	url('../../fonts/mandarin-whispers.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
*/
@font-face {
  font-family: "Karla";
  font-display: swap;
  src: local('Karla'), local('Karla-Regular'), url("../../fonts/Karla-Regular.eot"); /* IE9 Compat Modes */
  src: local('Karla'), local('Karla-Regular'), url("../../fonts/Karla-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/Karla-Regular.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/Karla-Regular.svg") format("svg"), /* Legacy iOS */
  url("../../fonts/Karla-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/Karla-Regular.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/Karla-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: "Karla";
  font-display: swap;
  src: local('Karla Bold'), local('Karla-Bold'), url("../../fonts/Karla-Bold.eot"); /* IE9 Compat Modes */
  src: local('Karla Bold'), local('Karla-Bold'), url("../../fonts/Karla-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/Karla-Bold.otf") format("opentype"), /* Open Type Font */
  url("../../fonts/Karla-Bold.svg") format("svg"), /* Legacy iOS */
  url("../../fonts/Karla-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
  url("../../fonts/Karla-Bold.woff") format("woff"), /* Modern Browsers */
  url("../../fonts/Karla-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
  unicode-range: U+000-5FF; /* Latin glyphs */
}