.section--central{
	text-align: center;
	text-align-last: center;

	h2{
		margin-bottom: 2rem;
	}

	p{
		@extend %p;
	}

	.rounded-image{
		border: .2rem solid $outline-contrast;
	}
}