// Single post
#page-content{
	position: relative;
	z-index: 2;
}

.rounded-image {
	@extend %rounded-image;
}

.play-button{
 	> * {
		display: inline-block;
		vertical-align: top;
	}

	i{
		display: inline-block;
		height: 7.4rem;
		width: 7.4rem;
		background-color: white;
		background-clip: content-box;
		border-radius: 50%;
		//border: 6px solid rgba(255,255,255,0.65);
		position: relative;
		animation: pulse 2s infinite;

		&:before{
			content: "";
			height: 0;
			width: 0;
			border-left: 2.3rem solid $accent-secondary;
			border-top: 1.5rem solid transparent;
			border-bottom: 1.5rem solid transparent;
			position: absolute;
			top: 0;
			left: 0.5rem;
			right: 0;
			bottom: 0;
			margin: auto;
			border-radius: .3rem;
		}
	}

	&__arrow{
		transform: rotate(45deg);
		margin-left: 1.5rem;
		margin-top: -1.5rem;
	}

	&__text{
		margin-top: -2rem;
		font-family: "Karla", sans-serif;
		color: white;
		font-size: 4.2rem;
		line-height: 4.9rem;
		max-width: 13rem;
		margin-left: 2rem;
	}

	@at-root {
		@keyframes pulse {
			0% {
				box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
			}
			85% {
				box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
			}
			100% {
				box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
			}
		}
	}

	@media (max-width: 420px){
		&__arrow{
			margin-left: 0.5rem;
		}

		&__text {
			font-size: 3.2rem;
			line-height: 3.9rem;
			max-width: 11rem;
			margin-left: 1rem;
		}
	}
}

.list--cats{
	list-style: none;
	margin: 0 auto;
	padding: 0;

	li{
		display: inline-block;
		vertical-align: middle;

		+ li {
			margin-left: 1rem;
		}

		a{
			padding: 0 0.3rem 0 0.4rem;
			line-height: 3.9rem;
			@extend %nav-item-chevron;

			&.active{
				&:before,
				&:after{
					opacity: 1;
				}
			}
		}
	}
}

.featured{
	padding-bottom: 6rem;
	margin-top: -13rem;

	.featured__content{
		margin-top: 13rem;
	}

	.featured__cat{
		font-weight: bold;
		font-size: 1.2rem;
	}

	p {
		@extend %p;
		color: $text;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		text-decoration: none;
		color: $text;
	}

	@include respond-to(large) {
		margin-top: 4rem;

		.featured__content{
			margin-top: 0;
		}
	}

	@include respond-to(small){
		.featured__content{
			padding-top: 2rem;
		}
	}
}

#blogroll{
	text-align: center;
	text-align-last: center;
	padding-bottom: 12rem;

	&:first-child{
		margin-top: 5rem;
	}

	.grid--posts{
		margin-top: -2rem;
		margin-bottom: 6rem;
		text-align: left;
		text-align-last: left;

		.post-link{
			display: block;
		}
	}

	[data-load-more]{
		display: none;
	}
}

.post-link{
	text-decoration: none;

	&__content{
		padding: 2rem 0;

		span{
			color: $text;
			font-size: 1.2rem;
			display: block;
			font-weight: bold;
			margin-bottom: 0.5rem;
		}

		h3 {
			min-height: 9rem;
			margin-bottom: 0;
		}
	}
}

.pagination{
	 > * {
		 display: inline-block;
		 padding: 0 0.5rem;
		 font-size: 1.8rem;
	 }
}

.text-wrapper {
	> img, picture {
		display: block;
		margin-bottom: 1.5rem;
	}

	@include headings {
		margin-bottom: 2rem;
		color: $heading;
	}

	p {
		@extend %p;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.card {
	@extend %shadow;
	@extend %light-background;
	text-decoration: none;
	display: block;
	background-color: #ffffff;
	border-radius: 0.6rem;
	padding: 4rem;

	&.card--bordered {
		border: 0.2rem solid $accent-tertiary;
	}

	&.card--no-shadow {
		box-shadow: none;
	}
}

.block {
	text-decoration: none;
	transition: background 0.2s ease-in;
	@include display-flex;
	@include flex-direction(column);
	@include align-items(flex-start);

	.block__icon {
		margin-bottom: 2rem;
		max-width: 13rem;
		max-height: 10rem;
	}

	.block__title {
		margin-bottom: auto;
		transition: color 0.2s ease-in;
		text-align: left;
	}

	.block__arrow {
		margin-top: 2rem;
		transition: transform 0.2s ease-in;
	}

	@at-root {
		.grid & {
			@include flex-grow(1);
		}

		button.block {
			cursor: pointer;

			&:focus {
				outline: none;
			}
		}
	}

	&.block--active {
		@extend %dark-background;
		background-color: $accent-primary;

		.block__arrow {
			transform: rotate(90deg);
		}
	}
}
