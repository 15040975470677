.section--cta {
	@extend %p;
	text-align: center;

	@include headings {
		color: $accent-primary;
	}

	.container {
		max-width: 76rem;
	}

	p {
		margin-bottom: 3rem;

		& + .btn {
			margin-top: 0;
		}

		&:last-child{
			margin-bottom: 0;
		}
	}

	.btn {
		margin-top: 2rem;
	}

	@include respond-to(xsmall){
		text-align: left;
	}
}
