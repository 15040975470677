.expandable {
	img {
		width: 100%;
		min-height: 30rem;
		max-height: 40rem;
		object-fit: cover;

		@include respond-to(medium) {
			max-height: none;
			min-height: 54rem;
		}
	}

	&--list {
		margin: 2rem 0;
	}

	&__content {
		@include respond-to(medium) {
			padding-top: 4rem;
		}
	}
}
.expandable__item {
	padding-left: 2.2rem;
	margin-bottom: 3rem;
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		width: 0.2rem;
		height: 100%;
		transform-origin: top left;
		background: $text-lighter;
		transition: background .2s ease-in-out;
	}

	.item__title {
		@extend %heading-5;
		font-weight: bold;
		transition: color .2s ease-in-out;
		padding: 0.5rem 0;
		margin-bottom: 0;
	}

	.item__copy {
		@extend %p;
		display: none;
		color: $text;
		opacity: 0;
		padding: 0.5rem 0;
		transform: translate(2rem, 0);
		transition: transform .35s ease-in-out,
		opacity .35s ease-in-out;

		p:last-child {
			margin-bottom: 0;
		}

		&.is-expanded {
			transition-delay: .2s;
			transform: translate(0);
			opacity: 1;
		}

		ul {
			@extend %list-unstyled;
			display: block;
			width: 100%;
			padding-top: 2rem;

			li {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				align-items: flex-start;
				line-height: 1.4;
				letter-spacing: 0.1rem;
				color: $text-lighter;

				&::before {
					content: '';
					min-width: 0.8rem;
					height: 0.8rem;
					margin-top: 0.8rem;
					border-radius: 50%;
					background-color: $accent-primary;
					margin-right: 2rem;
				}
			}
		}
	}

	&.is-active {
		&:before {
			background: $accent-secondary;
		}

		.item__title {
			color: $accent-primary;
		}
	}
}

.expandable--faq {
	margin: 0;

	.expandable__item {
		margin-bottom: 0;
		padding-left: 0;
		border-bottom: 0.2rem solid $outline-contrast;

		&:before {
			content: none;
		}

		&:first-child {
			border-top: 0.2rem solid $outline-contrast;
		}

		.item__title {
			font-weight: normal;
			padding: 2rem 0 2rem 3rem;
			color: $text-stand;
			position: relative;
			cursor: pointer;
			transition: color .2s ease-in-out;

			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 0;
				transform: translate(0, -50%);
				top: 50%;
				width: 1.2rem;
				height: 0.2rem;
				background: $accent-secondary;
				transition: transform .2s ease-in-out;
			}

			&:after {
				transform: translate(0, -50%) rotate(90deg) scale(1, 1);
			}
		}

		.item__copy {
			display: none;
			color: $text-lighter;
			padding: 0 0 3rem 3rem;
			opacity: 0;
			transform: translate(2rem, 0);
			transition: transform .35s ease-in-out, opacity .35s ease-in-out;

			&.is-expanded {
				transform: translate(0);
				opacity: 1;
			}
		}

		&.is-active {
			.item__title {
				color: $accent-primary;

				&:after {
					transform: translate(0, -50%) rotate(90deg) scale(0, 1);
				}
			}
		}
	}
}
