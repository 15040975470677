.section--staggered-grid{
	.expandable__item{
		cursor: default;

	}

	.section__header{
		max-width: 36rem;
		position: absolute;
		margin-bottom: 0;
	}

	.grid--staggered{
		.grid__item{
			text-align: center;
			text-align-last: center;

			&:before{
				display: none !important;
			}

			img{
				margin: 0 auto 3rem;
			}

			p{
				@extend %p;
				max-width: 32rem;
				margin: 0 auto;
			}
		}
	}

	@include respond-to(small){
		.section__header{
			position: relative;
			margin-bottom: 5rem;
			max-width: 100%;
		}

		.expandable__item{
			cursor: pointer;
		}

		.grid--staggered {
			height: auto !important;
			max-width: 52rem;
			margin: 0 auto -2.5rem;


			.grid__item {
				align-self: flex-start !important;
				padding-top: 2.5rem;
				padding-bottom: 2.5rem;
				margin-bottom: 0;


				&:before{
					display: none !important;
				}

				header{
					display: flex;
					align-items: center;
					margin-bottom: 0;
					transition: margin-bottom 0.2s linear;

					h4{
						flex-grow: 1;
						text-align: left;
						text-align-last: left;
						margin-bottom: 0;
						position: relative;
						padding-right: 1rem;

						&:before,
						&:after{
							content: '';
							background-color: $accent-primary;
							position: absolute;
							display: block;
							top: 0;
							bottom: 0;
							right: 0;
							margin: auto;
							transition: height .2s linear;
						}

						&:before{
							height: .2rem;
							width: 1rem;
						}

						&:after{
							width: .2rem;
							height: 1rem;
							right: .4rem;
						}
					}

					img{
						width: 10rem;
						margin-right: 5rem;
						margin-bottom: 0;
					}
				}

				p{
					max-width: 100%;
					text-align: left;
					text-align-last: left;
				}

				+ .grid__item{
					border-top: .2rem solid $outline-contrast;
				}

				&.is-active{
					header{
						margin-bottom: 2.5rem;

						h4:after{
							height: .2rem;
						}
					}
				}
			}
		}
	}

	@include respond-to(xxsmall){
		.section__header {
			margin-bottom: 2rem;
		}

		.grid--staggered {
			.grid__item {
				padding-left: 0;
				padding-right: 0;

				header {
					padding-right: 2rem;
					img {
						width: 6rem;
						margin-right: 2rem;
					}
				}
			}
		}
	}
}
