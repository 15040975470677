.section--customer-satisfaction {

    background-color: #F6F6FA;

    .page-tear {
        background-image: url("/wp-content/themes/class/resources/images/customer-satisfaction/page-tears.svg");
        background-repeat: no-repeat;
        width: 45%;
        height: 50%;
        position: absolute;
        margin-left: 82%;
        margin-top: 15%;
    }

    @media (max-width: 992px) {
        .page-tear {
            display: none;
        }
    }

    .face {
        max-width: 5%;
    }

    .faces {
        width: 100%;
    }

    .question-row {
        width: 80%;
        padding-bottom: 40px;
    }

    .row {
        padding-left: .8rem;
    }

    .question {
        position: relative;
        padding: 0;
        background-color: #F6F6FA;
    }

    input {
        display: inline-block;
    }

    .satisfaction-checkbox {
        margin: 10px;
        transform: scale(1.5);
        float: left;
        display: inline-block;
    }

    label::before {
        content: none !important;
    }

    input[type=radio] {
        display:none;
    }

    input[type=radio] + label {
        display: block;
        position: relative;
        height: 55px;
        width: 35px;
        text-align: center;
        padding-top: 40px;
        padding-right: 10px;
    }

    input[type=radio]:checked + label .after {
        .tick {
            display: block;
        }
    }

    input[type=radio] + label .after {
        height: 35px;
        width: 35px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        .tick {
            display: none;
        }
    }

    .checkbox-container {
        display: inline-block;
    }

    .checkbox-container:nth-child(3n+1) .checkbox-outline {
        transform: rotate(90deg);
        transform-origin: center;
    }

    .checkbox-container:nth-child(3n+2) .checkbox-outline {
        transform: rotate(180deg);
        transform-origin: center;
    }

    .error {
        display: none;
    }

}