.section--case-studies {
	@extend %transition-small-top;
	@extend %transition-curve-out-bottom;

	&:after {
		height: 17rem;
	}

	.tabslider {
		position: relative;
		z-index: 12;
	}

	@include respond-to(medium) {
		padding-bottom: 6rem;

		.tabslider {
			padding-top: 18rem;
		}
	}

	//Fix large gap as no images are provided yet
	@include respond-to(small) {
		padding-bottom: 6rem;

		.tabslider {
			padding-top: 3rem;
		}
	}
}
