.section--faq {
	@extend %transition-small-top;
	@extend %transition-small-bottom;

	background-image: url("../../../images/faq-squiggles.svg");
	background-size: 360px 443px;
	background-repeat: no-repeat;
	background-position: right center;

	.grid {
		margin-top: -$reflex-grid-spacing;
		margin-bottom: -$reflex-grid-spacing;
	}
}
