.section--overlap-media{
	p {
		@extend %p-lead;
	}

	.text-wrapper{
		margin-top: 4rem;
	}

	@include respond-to(small){
		.media-wrapper{
			text-align: center;

			.rounded-image{
				display: inline-block;
				max-width: 56rem;
			}

		}
	}
}
