[data-animation],
[data-child-class]{
	opacity: 0;
}

[data-animation="countUp"]{
	opacity: 1;
}

.animated{
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
	animation-duration: 0.4s;
}

.delay{
	@for $i from 1 through 30 {
		$num : $i / 10;
		$name : $i;

		&--#{$name} {
			$num : $i / 10;
			animation-delay: #{$num}s;
		}
	}
}

.duration{
	@for $i from 1 through 40 {
		$num : $i / 10;
		$name : $i;

		&--#{$name} {
			$num : $i / 10;
			animation-duration: #{$num}s;
		}
	}
}

.anim-header-btn{
	animation-name: headerBtn;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);

	@include respond-to(small){
		animation-name: smallFadeInUp;
	}

	@at-root {
		@keyframes headerBtn {
			from {
				transform: translateY(200px);
				opacity: 1;
			}
			to {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}

.fade-in{
	animation-name: fadeIn;

	@at-root {
		@keyframes fadeIn {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
	}
}

.small-fade-in-up{
	animation-name: smallFadeInUp;

	@at-root {
		@keyframes smallFadeInUp {
			from {
				opacity: 0;
				transform: translateY(1.5rem);
			}
			to {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}

.fade-in-up{
	animation-name: fadeInUp;

	@at-root {
		@keyframes fadeInUp {
			from {
				opacity: 0;
				transform: translateY(5rem);
			}
			to {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}

.fade-in-left{
	animation-name: fadeInLeft;

	@at-root {
		@keyframes fadeInLeft {
			from {
				opacity: 0;
				transform: translateX(-5rem);
			}
			to {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}
.small-fade-in-left{
	animation-name: smallFadeInLeft;

	@at-root {
		@keyframes smallFadeInLeft {
			from {
				opacity: 0;
				transform: translateX(-1.5rem);
			}
			to {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}

.small-fade-in-right{
	animation-name: smallFadeInRight;

	@at-root {
		@keyframes smallFadeInRight {
			from {
				opacity: 0;
				transform: translateX(1.5rem);
			}
			to {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}

.fade-in-right{
	animation-name: fadeInRight;

	@at-root {
		@keyframes fadeInRight {
			from {
				opacity: 0;
				transform: translateX(5rem);
			}
			to {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}
}



