.tabslider {
	.tabslider__views {
		@include display-flex;
		@include justify-content-space-between;
		@include align-items(center);
	}

	.tabslider__wrapper {
		@include flex(0, 0, calc(50% - 2rem));
		@include flex-basis(calc(50% - 2rem));
		@include display-flex;
		@include flex-direction(column);
		@include justify-content-space-between;

		@include respond-to(medium) {
			@include flex(0, 0, calc(100% - 2rem));
		}
	}

	.tabslider__items {
		position: relative;
	}

	.tabslider__item {
		position: absolute;
		top: 0;
		display: none;
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease-in-out;

		svg {
			display: block;
		}

		.item__copy {
			@extend %p-lead;
			margin: 2rem 0 2.5rem;
			transform: translate(10rem);
			opacity: 0;
			transition: transform .35s ease-in-out,
			opacity .35s ease-in-out;
		}

		.item__author {
			@extend %p-small;
			margin-bottom: 4rem;
			transform: translate(0, 1rem);
			opacity: 0;
			transition: transform .35s ease-in-out .25s,
			opacity .35s ease-in-out .25s;

			span {
				font-weight: 700;
				display: inline-block;
				margin-right: 1rem;

				@include respond-to(xsmall) {
					display: block;
					margin-bottom: 1rem;
				}
			}
		}

		.item__button {
			transform: translate(0, 1rem);
			opacity: 0;
			transition: all .35s ease-in-out;
		}

		&.is-visible {
			display: block;
		}

		&.is-active {
			opacity: 1;
			visibility: visible;

			.item__copy,
			.item__author,
			.item__button {
				transform: translate(0);
				opacity: 1;
			}

		}
	}

	.tabslider__images {
		position: relative;
		min-width: 0;
		margin-left: 10rem;
		@include flex(0, 1, 46rem);

		@include respond-to(medium) {
			position: absolute;
			width: auto;
			right: 2rem;
			margin-left: auto;
			bottom: calc(100% - 19.5rem);
		}

		& > img {
			margin-top: 8%;

			@include respond-to(medium) {
				display: none;
			}
		}

		.tabslider__image {
			@extend %shadow;
			border-radius: 0.6rem;
			overflow: hidden;
			z-index: 11;

			@include respond-to(medium) {
				min-height: 0;
				width: 21rem;
				//padding-top: 21rem;
				//transform: translate(0, -35%);
				max-width: 21rem;
				max-height: 21rem;
			}

			img {
				display: none;
				//object-fit: cover;
				max-width: 100%;

				@include respond-to(medium) {
					object-fit: initial;
					width: 100%;
				}

				&.is-active {
					display: block;
				}

			}

			&:after {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				transform: scale(0, 1);
				transform-origin: left center;
				background: $accent-secondary;
			}

			&.is-updating {
				&:after {
					transition: transform .3s ease-out;
					transform: scale(1, 1);
				}
			}

			&.is-leaving {
				&:after {
					transform-origin: right center;
					transition: transform .1s ease-in;
					transform: scale(0, 1);
				}
			}
		}
	}

	.tabslider__nav {
		margin: 2rem 0 0;

		@include display-flex;
		@include align-items(center);
		@include justify-content-space-between;

		.slider-nav-standard {
			margin: 0;
		}

		&.-bg {
			margin: 5rem 0;
		}
	}

	.tabslider__buttons {
		@extend %slider-buttons;
	}
}
