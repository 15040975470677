.form {

}

.form__group {
	position: relative;
	margin-bottom: 2.5rem;
	align-items: flex-start;
	@include display-flex;
	@include flex-direction(column);

	.form__label {
		position: absolute;
		left: 0.8rem;
		top: -0.8rem;
		display: inline-block;
		padding: 0 1rem;
		background-color: #ffffff;
		z-index: 2;
	}

	&.form__group--invalid {
		.form__label {
			color: $label-error;
		}

		.form__validation {
			@extend %alert--error;
			@include align-self(stretch);
		}
	}

	&:last-of-type{
		margin-bottom: 0;
	}

	.choices {
		margin-bottom: 0;
		@include align-self(stretch);
	}

	.grid {
		@include align-self(stretch);
	}
}

.form__label {
	@extend %p-xsmall;
	color: $accent-primary;
	font-weight: bold;
}

.form__control {
	@extend %form-control;

	&.form__control--button {
		padding-right: 15rem;

		~ .btn {
			position: absolute;
			top: 1rem;
			right: 1rem;
			min-width: 13rem;
		}
	}

	&.cfdatepicker {
		border-radius: 0.6rem;
		padding: 1.1rem 2rem 1rem;
	}
}

.form__validation:not(:empty) {
	@extend %alert;
	margin-top: 1rem;
}

input[type=checkbox],
input[type=radio] {
	display: none;

	& + label {
		@extend %p-xsmall;
		cursor: pointer;
		@include display-flex;
		@include align-items(center);

		&:before {
			content: '';
			margin-right: 1rem;
			border: 0.2rem solid $outline-contrast;
			width: 2.6rem;
			height: 2.6rem;
			border-radius: 0.6rem;
		}
	}
}

input[type=checkbox] + label {
	&:before {
		border-radius: 50%;
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.checkbox,
.radio {
	margin-bottom: 1rem;
}

.btn {
	@extend %btn;
	min-width: 15rem;

	&.btn--primary {
		background-color: $accent-primary;
		border-color: $accent-primary;
		color: #ffffff;

		&:hover,
		&:focus {
			box-shadow: $box-shadow-primary;
		}
	}

	&.btn--outline {
		background-color: transparent;
		color: $accent-primary;
		border-color: initial;

		&:hover,
		&:focus {
			box-shadow: none !important;
			border-color: $accent-secondary !important;
		}
	}

	&.btn--glowing {
		background-color: #cafffd;
		border-color: #cafffd;
		box-shadow: 0 0 20px 5px #1bf79b;
	}

	&.btn--xs {
		padding: 0.6rem 1.3rem;
		min-width: 8rem;
		font-size: 1.4rem;
		line-height: 1.4rem;
	}

	&.btn--sm {
		padding: 1.1rem 2.8rem;
		min-width: 10rem;
		font-size: 1.4rem;
		line-height: 1.4rem;
	}

	&.btn--lg {
		padding: 1.9rem 5.8rem;
		font-size: 1.9rem;
		line-height: 1.8rem;
		min-width: 20rem;
	}

	&.btn--xlg {
		padding: 2.9rem 7.3rem;
		font-size: 1.9rem;
		line-height: 1.8rem;
		letter-spacing: -0.1rem;
		min-width: 20rem;
		border-radius: 4rem;
	}
}

.alert {
	@extend %alert;
	margin-bottom: 2rem;

	&.alert--error {
		@extend %alert--error;
	}

	&[hidden] {
		display: none;
	}
}
