.wp-block-table,
.table {
	border: 0.2rem solid $outline-contrast;
	border-radius: 0.6rem;
	width: 100%;
	border-spacing: 0;

	&.table--captioned {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	caption {
		@extend %heading-3;
		color: $heading;
		border: inherit;
		margin: 0;
		text-align: left;
		border-bottom: 0;
		border-radius: 0.6rem 0.6rem 0 0;
		padding: 2rem;
	}

	th, td {
		padding: 2rem;
		text-align: left;
	}

	th {
		@extend %p-small;
		font-weight: bold;
	}

	td {
		@extend %p;
	}

	tbody {
		tr:nth-child(2n+1) {
			background-color: $accent-tertiary;
		}
	}

	tfoot {
		td {
			@extend %p-xsmall;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	@at-root {
		dl.table,
		ul.table {
			list-style: none;

			p:last-child {
				margin-bottom: 0;
			}
		}

		dl.table {
			list-style: none;

			div {
				padding: 1.5rem 2rem;
				@include display-flex;
				@include flex-direction(column);

				dt {
					@extend %p-xsmall;
				}

				dd {
					@extend %p-small;
					font-weight: bold;
					color: $accent-primary;
					margin: 0;
					white-space: pre-wrap;
				}

				&:nth-child(2n) {
					background-color: $accent-tertiary;
				}
			}
		}

		ul.table {
			list-style: none;

			li {
				padding: 2rem 2rem;
				@extend %p-small;
				color: $accent-primary;

				&:nth-child(2n) {
					background-color: $accent-tertiary;
				}
			}
		}
	}

	@include respond-to(xsmall){

		display: block;
		overflow-x: auto;
		white-space: nowrap;

		&.table--captioned {
			border-top-left-radius: 0.6rem;
			border-top-right-radius: 0.6rem;
		}

		caption{
			border: none;
			border-bottom: 0.2rem solid $outline-contrast;
		}
	}
}
