%heading-1 {
	font-size: 4.8rem;
	letter-spacing: -0.363rem;
	line-height: 6rem;
	margin-bottom: 2.3rem;

	@include respond-to(xsmall) {
		font-size: 3.6rem;
		line-height: 4.5rem;
		letter-spacing: -0.25rem;
	}
}

%heading-2 {
	font-size: 3.6rem;
	letter-spacing: -0.25rem;
	line-height: 4.5rem;
	margin-bottom: 1rem;
	font-weight: bold;

	// Might break stuff.
	@include respond-to(small) {
		font-size: 3rem;
		line-height: 3.7rem;
		letter-spacing: -0.2rem;
	}
}

%heading-3 {
	font-size: 2.2rem;
	letter-spacing: -0.1rem;
	line-height: 2.8rem;
	margin-bottom: 0.9rem;
	font-weight: bold;
}

%heading-4 {
	font-size: 1.8rem;
	letter-spacing: -0.1rem;
	line-height: 2.3rem;
	margin-bottom: 0.6rem;
	color: $accent-primary;
	font-weight: bold;
}

%heading-5 {
	font-size: 1.6rem;
	font-weight: normal;
	letter-spacing: -0.075rem;
	line-height: 2rem;
	margin-bottom: 0.6rem;
}

%heading-6 {
	font-size: 1.2rem;
	color: $text;
	font-weight: bold;
	line-height: 1.4rem;
	margin-bottom: 0.6rem;
}

%p {
	font-size: 1.8rem;
	line-height: 2.5rem;
	letter-spacing: -0.1rem;
	font-weight: normal;
}

%p-lead {
	font-size: 2.2rem;
	line-height: 3.1rem;
	letter-spacing: -0.1rem;
	font-weight: normal;
}

%p-small {
	font-size: 1.6rem;
	line-height: 2.3rem;
	letter-spacing: -0.075rem;
	font-weight: normal;
}

%p-xsmall {
	font-size: 1.4rem;
	line-height: 1.9rem;
	letter-spacing: -0.05rem;
	font-weight: normal;
}

%list-unstyled {
	list-style: none;
	padding: 0;
	margin: 0;
}

.numbered-list {
	list-style: revert !important;
}

%light-background {
	color: $text;

	a:not(.btn) {
		color: $accent-primary;

		p {
			color: $text;
		}
	}

	h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
		color: $heading;
	}

	p {
		color: inherit;
	}

	.btn {
		a {
			color: inherit;
		}

		&.btn--outline {
			color: $accent-primary;
		}

		&.btn--primary {
			background-color: $accent-primary;
			color: #ffffff;
		}
	}

	.nav {
		li:not(.btn) a {
			color: $accent-primary;

			&:hover,
			&:focus {
				color: $text-secondary !important;
			}
		}
	}
}

%dark-background {
	color: #ffffff;

	h1, .h1, h2, .h2, h3, .h3, h4, .h4, p, a:not(.btn) {
		color: #ffffff;
	}

	.btn {
		a {
			color: inherit;
		}

		&.btn--outline {
			color: inherit;
		}

		&.btn--primary {
			background-color: $accent-secondary;
			color: $accent-primary;
			border-color: $accent-secondary;
		}
	}

	.nav {
		li:not(.btn) a {
			color: inherit;

			&:hover,
			&:focus {
				color: $accent-secondary !important;
			}
		}
	}
}

%shadow {
	box-shadow: 0 50px 60px -30px rgba($text-stand, 0.3);

	&-hover {
		box-shadow: 0 50px 60px -30px rgba($text-stand, 0.2);
		transition: box-shadow 0.3s ease-in-out;

		&:hover {
			box-shadow: 0 60px 80px -30px rgba($text-stand, 0.3);
		}
	}

	@include respond-to(small){
		box-shadow: 0 20px 30px -30px rgba(47, 33, 67, 0.3);


		&-hover {
			box-shadow: 0 20px 20px -30px rgba($text-stand, 0.2);
			transition: box-shadow 0.3s ease-in-out;

			&:hover {
				box-shadow: 0 20px 30px -30px rgba($text-stand, 0.3);
			}
		}
	}
}

%btn {
	appearance: none;
	box-shadow: none;
	outline: none;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	font-family: $karla;
	font-weight: bold;
	color: $accent-primary;
	background-color: $accent-secondary;
	border: 0.2rem solid $accent-secondary;
	font-size: 1.6rem;
	line-height: 1.6rem;
	letter-spacing: -0.075rem;
	border-radius: 3.5rem;
	text-align: center;
	text-align-last: center;
	padding: 1.5rem 3.8rem;
	min-width: 12.5rem;
	transition: all .3s ease-in-out;

	> a {
		display: block;
		height: 100%;
		width: 100%;
		color: inherit;
	}

	&[disabled] {
		background-color: #E2E0E4 !important;
		color: #ffffff !important;
		border-color: #E2E0E4 !important;

		&:hover,
		&:focus {
			color: #ffffff !important;
			border-color: #E2E0E4 !important;
			box-shadow: none !important;
			cursor: not-allowed;
		}
	}

	&:hover,
	&:focus {
		color: $accent-primary;
		box-shadow: $box-shadow-secondary;
	}
}

%slider-buttons {
	@extend %list-unstyled;
	@include display-flex;

	li {
		margin-left: 1rem;

		button {
			appearance: none;
			box-shadow: none;
			outline: none;
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			position: relative;
			cursor: pointer;
			border: none;
			background-color: $accent-secondary;
			-webkit-tap-highlight-color: transparent;

			&[disabled] {
				pointer-events: none;
				background: $outline-contrast;
				transform: scale(.75);
				box-shadow: none;
			}

			@include respond-to(large) {
				box-shadow: none;
			}
		}
	}
}

%nav-item-chevron{
	display: block;
	position: relative;

	&:before {
		content: '';
		width: 100%;
		height: .3rem;
		background-color: $accent-secondary;
		position: absolute;
		left: 0;
		bottom: 0.4rem;
		opacity: 0;
		border-radius: .5rem;
		overflow: hidden;

		@at-root .header--secondary & {
			background-color: $accent-primary;
		}
	}

	&:after {
		content: "";
		border-top: .7rem solid $accent-secondary;
		border-left: .7rem solid transparent;
		border-right: .7rem solid transparent;
		position: absolute;
		height: 0;
		width: 0;
		margin: auto;
		left: 0;
		right: 0;
		bottom: -.3rem;
		opacity: 0;

		@at-root .header--secondary & {
			border-top-color: $accent-primary;
		}
	}
}

%rounded-image {
	@extend %shadow;
	line-height: 0;
	font-size: 0;
	border-radius: .5rem;
	overflow: hidden;
	position: relative;
	display: block;

	.play-button {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 2rem;

		@media (max-width: 420px) {
			padding: 1rem;
		}
	}

	img {
		border-radius: inherit;
		max-width: 100%;
	}

	&.no-shadow{
		box-shadow: none;
	}

	@include respond-to(medium) {
		img{
			max-width: 100%;
		}
	}
}

%alert {
	@extend %p-xsmall;
	font-weight: bold;
	display: block;
	background-color: $accent-error;
	color: $text-error;
	border-radius: 0.6rem;
	padding: 1.5rem 3rem 1.5rem 5rem;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		display: block;
		top: 1.5rem;
		left: 1.25rem;
		width: 2.5rem;
		height: 2.1rem;
	}

	&%alert--error {
		background-color: $accent-error;
		color: $text-error;

		&:before {
			background-image: url(../../images/icons/icon--warning.svg);
		}
	}
}

%form-control {
	@extend %p;
	appearance: none;
	box-shadow: none;
	border: 0.2rem solid $outline-contrast;
	border-radius: 0.6rem;
	padding: 1.1rem 2rem 1rem;
	color: $text;
	font-family: $karla;
	width: 100%;

	&:focus {
		border-color: $accent-secondary;
		outline: none;
		box-shadow: none;
	}

	&.form__control--invalid {
		background-image: url(../../images/icons/icon--cross.svg);
		background-size: 1.3rem 1.2rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 2rem) center;
	}

	&.form__control--valid {
		background-image: url(../../images/icons/icon--tick.svg);
		background-size: 1.1rem 1rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 2rem) center;
	}
}

%post-content{
	a {
		color: #118E48;
		text-decoration: underline;
	}

	p {
		@extend %p;

		img {
			margin: 0 auto;
			display: block;
			max-width: 100%;
		}

		+ h1, + h2, + h3, + h4, + h5, + h6{
			margin-top: 6rem;
		}
	}

	figure{
		margin: 3rem auto;
	}

	ol {
		@extend %list-unstyled;
		margin: 3rem;

		+ li{
			margin-top: 1rem;
		}
	}

	ul {
		@extend %list-unstyled;
		margin-bottom: 2.5rem;

		li {
			padding-left: 2rem;
			font-size: 1.8rem;
			position: relative;

			+ li{
				margin-top: 1rem;
			}

			&:before {
				content: "\2022";
				font-size: 2.4rem;
				line-height: 1;
				position: absolute;
				left: 0;
				top: 0;
				color: $accent-secondary;
				padding-right: 2rem;
				vertical-align: middle;
			}
		}

		+ h1, + h2, + h3, + h4, + h5, + h6{
			margin-top: 6rem;
		}
	}

	.has-vivid-red-color {
		color: #cf2e2e;
		padding: 2rem 0;
		margin-bottom: 3rem;
		margin-top: 3rem;
		border-top: 0.1rem solid $outline-contrast;
		border-bottom: 0.1rem solid $outline-contrast;

		&:first-child{
			margin-top: 0;
		}
	}
}

%embed-responsive{
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
