#footer {
	background-color: #ffffff;
	padding-top: 6rem;
	padding-bottom: 2rem;
	border-top: 0.2rem solid #E2E0E4;

	&__logo {
		svg {
			#text {
				fill: $accent-primary;

				@include respond-to(xxsmall) {
					fill: none;
				}
			}
		}
	}

	&__nav {
		.nav li a {
			font-weight: normal;
		}

		@include respond-to(xxsmall) {
			padding: 0.5rem 0;
			margin: 0 $reflex-grid-spacing 1.5rem;
			border-bottom: 0.2rem solid #E2E0E4;

			.nav {
				@include flex-direction(row);
				@include flex-wrap(wrap);

				li {
					width: 50%;
					width: calc(100% / 2);
					margin-top: 0;
					margin-bottom: 2rem;
				}
			}
		}
	}

	&__copyright {
		font-size: 1.2rem;
		line-height: 1.9rem;
		letter-spacing: -0.043rem;
	}

	&__social,
	&__apps,
	&__help {
		margin-bottom: 3rem;
	}

	&__apps {
		@include display-flex;
		@include align-items(center);

		a + a {
			margin-left: 0.5rem;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}

		.google-play,
		.app-store {
			display: block;
		}

		.google-play {
			max-width: 11.5rem;
		}

		.app-store {
			max-width: 13.5rem;
		}
	}

	&__help {
		@include respond-to(xxsmall) {
			margin-bottom: 2rem;

			li {
				margin-bottom: 1.5rem;
			}
		}
	}

	@include respond-to(small) {
		padding-top: 2rem;
	}

	@include respond-to(xxsmall) {
		padding-top: 0.5rem;
		padding-bottom: 0rem;
	}
}
