.section--media-copy {
	.media-wrapper {
		> * {
			z-index: 1;
		}

		&:after {
			content: '';
			width: 12.6rem;
			height: 13.6rem;
			background-image: url("../../../images/decoration--droplets.svg");
			background-size: 12.6rem 13.6rem;
			position: absolute;
			bottom: -4rem;
			right: -3rem;
			transform: rotateY(180deg);
			z-index: 0;
		}
	}

	.bg{
		margin-top: 1rem;
		margin-bottom: 1rem;
		padding: 10rem 0;
		min-height: 40rem;

		&:before{
			content: '';
			height: 3.8rem;
			width: calc(80% + ((100vw - 100%) / 2) );
			background-image: url("../../../images/transitions/small--bottom.svg");
			background-size: 100% 3.8rem;
			position: absolute;
			top: -3.8rem;
			right: 20%;
			z-index: 1;
		}

		&:after{
			content: '';
			height: calc(100% + 3.8rem);
			width: calc(80% + ((100vw - 100%) / 2) );
			background-image: linear-gradient(#fff, #fff), url("../../../images/transitions/small--top.svg");
			background-size: 100% calc(100% - 3.8rem), 100% 3.8rem;
			background-position: center top, 100% 100%;
			background-repeat: no-repeat;
			position: absolute;
			bottom: -3.7rem;
			right: 20%;
			z-index: 1;
		}

		> * {
			z-index: 2;
		}

		&--tertiary{
			&:before{
				background-image: url("../../../images/transitions/small--bottom-grey.svg");
			}
			&:after{
				background-image: linear-gradient($accent-tertiary, $accent-tertiary), url("../../../images/transitions/small--top-grey.svg");
			}
		}

		&--primary{
			.text-wrapper {
				@extend %dark-background;
			}

			&:before {
				background-image: url("../../../images/transitions/small--bottom-primary.svg");
			}

			&:after {
				background-image: linear-gradient($accent-primary, $accent-primary), url("../../../images/transitions/small--top-primary.svg");
			}
		}

		&--secondary{
			.text-wrapper {
				@extend %light-background;
			}

			&:before {
				background-image: url("../../../images/transitions/small--bottom-secondary.svg");
			}

			&:after {
				background-image: linear-gradient($accent-secondary, $accent-secondary), url("../../../images/transitions/small--top-secondary.svg");
			}
		}
	}

	&.section--media-right {
		.media-wrapper:after {
			right: auto;
			left: -3rem;
			transform: rotateY(0deg);
		}
	}

	&.section--media-left{
		.bg{
			&:before,
			&:after{
				right: auto;
				left: 20%;
			}
		}
	}

	@include respond-to(small){
		&,
		&.section--media-left {
			.bg {
				padding: 5rem 0;

				&:before,
				&:after {
					left: 0;
					right: 0;
					width: 100%;
				}
			}
		}

		.media-wrapper{
			max-width: 56rem;
			margin: 0 auto 5rem;
		}
	}
}
