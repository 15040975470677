.section--stats{
    .grid {
        + .grid{
            border-top: .2rem solid $accent-secondary;
        }

        [class*="col-"]{
            border-right: .2rem solid $accent-secondary;

            &:nth-child(3){
                border-right: none;
            }
        }

        &:first-of-type{
            .stat{
                padding-top: 1rem;
            }
        }
    }

    .stat{
        padding: 3rem 4rem;

        p{
            margin : 0;
            @extend %p;
        }
    }

    @include respond-to(small){
        .grid {
            max-width: 50rem;
            margin: 0 auto;

            [class*="col-"] {
                border-right: none;

                + [class*="col-"] {
                    border-top: .2rem solid $accent-secondary;
                }
            }

            &:first-of-type{
                .stat{
                    padding-top: 3rem;
                }
            }
        }
    }

    @include respond-to(xxsmall){
        .stat{
            padding: 2rem 2rem;
        }

        .grid:first-of-type{
            .stat{
                padding-top: 2rem;
            }
        }
    }
}