.section--contact {
	.blocks {
		margin-top: -$reflex-grid-spacing;
		margin-bottom: 4rem;
	}

	.block-content {
		display: none;
		margin-top: -6rem;
		padding-top: 6rem;
	}
}
