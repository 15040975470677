@import "~normalize-scss/sass/normalize";
@include normalize();

@import "theme/fonts";
@import "theme/variables";

//Reflex
$reflex-grid-spacing: 2rem;
$visibility-helpers: false;
@import "~reflex-grid/scss/reflex";

// Choices
@import "~choices.js/src/styles/choices";

//Mixins
@import "mixins/class";
@import "mixins/extendables";
@import "mixins/transitions";

@include responsive-visibility-helpers();

//Theme
@import "theme/base";
@import "theme/forms";
@import "theme/choices";
@import "theme/tables";
@import "theme/header";
@import "theme/expandable";
@import "theme/carousel";
@import "theme/tabslider";
@import "theme/content";
@import "theme/sections";
@import "theme/post";
@import "theme/footer";
@import "theme/xdebug";
@import "theme/animations";
