.section--static-form{

	.text-wrapper{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-top: 7rem;

		&:after {
			content: "";
			display: block;
			background-image: url("../../../images/callback-squiggles.svg");
			height: 230px;
			width: 313px;
			background-size: 310px 363px;
			background-repeat: no-repeat;
			background-position: right center;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			left: calc(100% - 18rem);
		}

		.copy-wrapper{
			 > * {
				 margin-bottom: 0;
			 }

			 strong{
			 	font-size: 3.6rem;
			 	display: block;
			 	line-height: 4rem;
			 }
		}
	}

	.form-wrapper{
		background-color: white;
		border-radius: .5rem;
		overflow: hidden;
		padding: 5rem 5rem 4rem;
		@extend %shadow;
	}

	.top-element{
		z-index: 3;
	}

	.bg{
		padding: 6rem 0;

		&:before{
			width: calc(60% + ((100vw - 100%) / 2) );
			right: 40%;
		}

		&:after{
			width: calc(60% + ((100vw - 100%) / 2) );
			right: 40%;
		}
	}

	@include respond-to(small) {
		&,
		&.section--media-left {
			margin-bottom: 9rem;

			.bg {
				padding: 5rem 0;

				&:before,
				&:after {
					left: 0;
					right: 0;
					width: 100%;
				}
			}

			.text-wrapper{
				text-align: left;
				text-align-last: left;
				align-content: left;
				padding-top: 0;
				padding-bottom: 5rem;
			}

			.form-wrapper{
				padding: 3rem 3rem 2rem;
				margin-bottom: -15rem;
			}
		}
	}
}