.section--usps {


	.grid__item {
		@include headings {
			margin-bottom: 1.5rem;
		}

		picture, svg, > img {
			display: inline-block;
			margin-bottom: 1.5rem;
			max-width: 8rem;
		}

		svg {
			margin-bottom: 0.5rem;
			width: 1.1rem;
			height: 1rem;
		}

		p:last-child {
			margin-bottom: 0;
		}

		&.grid__item--circle {
			text-align: center;
			position: relative;

			@include headings {
				margin-bottom: 0.5rem;
			}

			img {
				max-width: 100%;
			}

			.item__copy {
				color: $accent-primary;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				padding: 4rem;
				height: 26rem;
				margin: auto;
			}

			p {
				@extend %p;
				margin-bottom: 1rem;
			}

			small {
				@extend %p-xsmall;
				font-weight: bold;
			}

			@include respond-to(small) {
				img {
					display: none;
				}

				.item__copy {
					position: static;
					padding: 0;
					height: auto;
					color: $text;
				}
			}
		}
	}
}

.grid--usps {
	margin-top: -$reflex-grid-spacing;
	margin-bottom: -$reflex-grid-spacing;

	&.grid--usps-stats > div {
		color: $accent-primary;
		position: relative;
		padding-right: 4rem;
		padding-top: 4rem;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: $reflex-grid-spacing;
			left: $reflex-grid-spacing;
			right: $reflex-grid-spacing;
			border-top: 0.2rem solid $outline-contrast;
		}
	}
}