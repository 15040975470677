@mixin retina-bg-pseudo($width, $height, $overridePWidth: 0, $overridePHeight: 0) {
	display: block;
	width: $width;
	height: $height;
	background-size: $width $height;
	background-position: center;
	background-repeat: no-repeat;
}

@mixin abs-center($top: 0, $left: 0, $right: 0, $bottom: 0) {
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
	margin: auto;
}

@mixin font-size($sizeValue: 1.4) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}

@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin respond-to($media) {

	@if $media == xxsmall {
		@media only screen and (max-width: ($reflex-xs - 1)) {
			@content;
		}
	}
	@if $media == xsmall {
		@media only screen and (max-width: 600px) {
			@content;
		}
	}
	@if $media == small {
		@media only screen and (max-width: ($reflex-sm - 1)) {
			@content;
		}
	}
	@if $media == medium {
		@media only screen and (max-width: ($reflex-md - 1)) {
			@content;
		}
	}
	@if $media == large {
		@media only screen and (max-width: ($reflex-lg - 1)) {
			@content;
		}
	}
	@if $media == xlarge {
		@media only screen and (max-width: ($reflex-xlg - 1)) {
			@content;
		}
	}
	@if $media == desktop {
		@media only screen and (min-width: ($reflex-xlg - 1)) {
			@content;
		}
	}
	@if $media == retina {
		@media only screen and (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
			@content;
		}
	}
}

@mixin headings {
	h1, .h1,
	h2, .h2,
	h3, .h3,
	h4, .h4,
	h5, .h5,
	h6, .h6 {
		@content;
	}
}

@function intrinsic-ratio-calc($width, $height) {
	@return (($height / $width) * 100%);
}

@mixin intrinsic-ratio($width, $height) {
	position: relative;

	&:before {
		content: '';
		display: block;
		height: 0;
		width: 100%;
		padding-top: intrinsic-ratio-calc($width, $height);
	}

	> img,
	> picture {
		position: absolute;
		top: 0;
		left: 0;
	}
}

@mixin responsive-visibility-helpers() {
	.#{$reflex-prefix}hidden-xxs {
		@media (max-width: $reflex-xxs-max) {
			display: none !important;
		}
	}

	.#{$reflex-prefix}hidden-xs {
		@media (min-width: $reflex-xs) and (max-width: $reflex-xs-max) {
			display: none !important;
		}
	}

	.#{$reflex-prefix}hidden-sm {
		@media (min-width: $reflex-sm) and (max-width: $reflex-sm-max) {
			display: none !important;
		}
	}

	.#{$reflex-prefix}hidden-md {
		@media (min-width: $reflex-md) and (max-width: $reflex-md-max) {
			display: none !important;
		}
	}

	.#{$reflex-prefix}hidden-lg {
		@media (min-width: $reflex-lg) {
			display: none !important;
		}
	}
}
